import { StatusService } from '@services';
import { interval as observableInterval, Subscription } from 'rxjs';
import { WindowRef } from '@shared/windowRef';
import { TranslateService } from '@ngx-translate/core';
import { CanvasComponent } from './../../fabric/canvas/canvas.component';
import {
  PainType,
  Template,
  TemplateBundle,
  Chart,
  Settings,
  Submission,
  Questionnaire,
  PainIntensity,
} from '@models';
import { TourService } from 'ngx-tour-md-menu';
import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  TemplateRef,
  QueryList,
  ViewChildren,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TdLoadingService } from '@covalent/core/loading';
import { TdDialogService } from '@covalent/core/dialogs';
import { Angulartics2 } from 'angulartics2';
import { Router } from '@angular/router';
import { debounce } from '@shared/debounce';

@Component({
  selector: 'app-draw',
  templateUrl: './draw.component.html',
  styleUrls: ['./draw.component.scss'],
})
export class DrawComponent implements OnInit, OnDestroy {
  @ViewChildren('drawings') drawings!: QueryList<CanvasComponent>;
  @ViewChild('templateDialog') templateDialog!: TemplateRef<any>;
  @ViewChild('saveDialog') saveDialog!: TemplateRef<any>;
  @ViewChild('painTypeDialog') painTypeDialog!: TemplateRef<any>;
  @Input() set patient(patient: any) {
    this._settings = patient.PatientOrganizations[0];
    this._settings.PatientId = patient.Id;
  }
  @Input() personnelView = false;
  @Input() showStartupDialog = true;
  @Output() save: EventEmitter<Submission> = new EventEmitter<Submission>();
  maxNoteLength = 80;
  overlays!: string[];
  saveDialogOpen = false;
  painTypeDialogOpen = false;
  painTypeSelected = false;
  afterFirstView = false;
  drawingHeight!: number;
  drawingWidth!: number;
  documentHeight!: number;
  documentWidth!: number;
  templateChanged = false;
  warnFirstUse = false;
  isInitialized = false;
  questionnaireIndex = -1;
  painTypeIndex = 0;
  painIntensityIndex = 0;
  currentTemplateIndex = 0;
  penSizes = [20, 10, 5, 1];
  penSize = 10;
  painLevel = 0;
  usualPainLevel = 0;
  isDrawing = true;
  note = '';
  infoMessage?: string;
  noPain = false;
  templateDrawings?: any[];

  initSubscription!: Subscription;

  _settings!: Settings;

  @Input() set settings(settings: Settings) {
    this._settings = settings;
    this.penSizes = settings.DrawingSettings.PenSizes;
    this.penSize = settings.DrawingSettings.PenSize;
  }

  get settings(): Settings {
    return this._settings;
  }

  @HostListener('window:resize', ['$event'])
  @debounce()
  onResize(event: Event) {
    this.setDrawingDims();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.saveDialogOpen) {
      if (event.ctrlKey && event.key.toLowerCase() === 's') {
        event.preventDefault();
      }
      return;
    }
    switch (event.key.toLowerCase()) {
      case 's': // Ctrl + s
        if (event.ctrlKey) {
          this.showSaveDialog();
          event.preventDefault();
          this.trackEvent('OpenSaveDialog');
        }
        break;
      case 'z': // Ctrl + z
        if (event.ctrlKey) {
          this.undo();
          this.trackEvent('Undo');
        }
        break;
      case 'y': // Ctrl + y
        if (event.ctrlKey) {
          this.redo();
          this.trackEvent('Redo');
        }
        break;
      case '+': // +
        this.zoom(1.15);
        this.trackEvent('ZoomIn', this.activeDrawing?.zoomLevel);
        break;
      case '-': // -
        this.zoom(0.85);
        this.trackEvent('ZoomOut', this.activeDrawing?.zoomLevel);
        break;
      /*
      case 68:
        if (event.ctrlKey) {
          this.isDrawing = !this.isDrawing;
          event.preventDefault();
        }
        break;
      case 70:
        if (event.ctrlKey) {
          this.resetZoom();
          event.preventDefault();
        }
        break;
      */
      case 'p': // P
        this.prevTemplate();
        this.trackEvent('PreviousTemplate');
        break;
      case 'n': // N
        this.nextTemplate();
        this.trackEvent('NextTemplate');
        break;
      case 'arrowup': // Arrow up
        this.moveVertical(true);
        this.trackEvent('MoveUp');
        break;
      case 'arrowdown': // Arrow down
        this.moveVertical(false);
        this.trackEvent('MoveDown');
        break;
      case 'arrowright': // Arrow right
        this.moveHorizontal(true);
        this.trackEvent('MoveRight');
        break;
      case 'arrowleft': // Arrow left
        this.moveHorizontal(false);
        this.trackEvent('MoveLeft');
        break;
      case 'q': // Ctrl + Q
        if (event.ctrlKey && !this.painTypeDialogOpen) {
          this.openPainTypeDialog();
          this.trackEvent('OpenPainTypeDialog');
        }
        break;
      default:
        break;
    }
  }

  constructor(
    private _loadingService: TdLoadingService,
    public _dialogService: MatDialog,
    public _tdDialogService: TdDialogService,
    private element: ElementRef,
    private winRef: WindowRef,
    private router: Router,
    private statusService: StatusService,
    private tourService: TourService,
    private snackBar: MatSnackBar,
    private angulartics2: Angulartics2,
    private translate: TranslateService
  ) {}

  get disablePrevQuestionnaire(): boolean {
    return this.questionnaireIndex <= -1;
  }

  get isLastQuestionaire(): boolean {
    return (
      !this.questionnaires.length ||
      this.questionnaireIndex >= this.questionnaires.length - 1
    );
  }

  get currentQuestionnaireAnswered(): boolean {
    if (this.currentQuestionnaire) {
      for (const question of this.currentQuestionnaire.Questions) {
        if (question.Required && question.Answer == undefined) {
          return false;
        }
      }
    }
    return true;
  }

  get disableNextQuestionnaire(): boolean {
    return this.isLastQuestionaire || !this.currentQuestionnaireAnswered;
  }

  setQuestionnaireIndex(change: number) {
    this.questionnaireIndex = Math.max(
      Math.min(
        this.questionnaireIndex + change,
        this.questionnaires.length - 1
      ),
      -1
    );
  }

  get questionnaires(): Questionnaire[] {
    return this.settings.DrawingSettings.Questionnaires &&
      this.settings.DrawingSettings.Questionnaires.length
      ? this.settings.DrawingSettings.Questionnaires
      : [];
  }

  get currentQuestionnaire(): Questionnaire | undefined {
    return this.questionnaires && this.questionnaires.length
      ? this.questionnaires[this.questionnaireIndex]
      : undefined;
  }

  get saveButtonDisabled(): boolean {
    return (
      !!this.currentQuestionnaire &&
      !!this.currentQuestionnaire.Invalid &&
      (!(this.currentQuestionnaire && !!this.currentQuestionnaire.Invalid) ||
        !this.currentQuestionnaireAnswered)
    );
  }

  get painIntensity(): PainIntensity {
    return this.painType.PainIntensities[this.painIntensityIndex];
  }

  get painType(): PainType {
    return this.painTypes[this.painTypeIndex];
  }

  get painTypes(): PainType[] {
    return this.settings.DrawingSettings.PainTypes;
  }

  get templates(): Template[] {
    return this.settings.TemplateBundle.Templates;
  }

  trackEvent(
    action: string,
    value?: string | number | undefined,
    category = 'Drawing',
    label = 'keyboard'
  ) {
    this.angulartics2.eventTrack.next({
      action: action,
      properties: {
        label: label,
        category: category,
        value: value,
      },
    });
  }

  ngOnInit() {
    if (this.personnelView) {
      this.statusService.researchOrganizationAccessLevel.subscribe(
        (accessLevel) => {
          this.overlays = accessLevel.Overlays || [];
        }
      );
    }
    this.showTemplate(this.templateBundle.Templates[0], false);
    this.initSubscription = observableInterval(250).subscribe(() =>
      this.checkIfInitialized()
    );
  }

  ngOnDestroy() {
    this.initSubscription.unsubscribe();
  }

  selectNoPain() {
    this.noPain = this.allEmptyDrawings;
    this._tdDialogService.closeAll();
    this.openSaveDialog();
  }

  openStartDialog() {
    this.showTemplateDialog(() => {
      if (!this.noPain) {
        if (this.painTypes.length === 1) {
          if (
            this.painTypes[0].PainIntensities &&
            this.painTypes[0].PainIntensities.length
          ) {
            this.painTypeSelected = true;
            this.openPainTypeDialog();
          }
        } else if (this.painTypes.length > 1) {
          this.openPainTypeDialog();
        }
      }
    });
  }

  openPainTypeDialog() {
    this.afterFirstView = true;
    this.painTypeDialogOpen = true;
    this._dialogService
      .open(this.painTypeDialog)
      .afterClosed()
      .subscribe(
        () => (this.painTypeSelected = this.painTypeDialogOpen = false)
      );
  }

  checkIfInitialized() {
    if (!this.isInitialized) {
      if (
        this.drawings &&
        this.drawings.map((d) => d.isInitialized).every((d) => d)
      ) {
        this.isInitialized = true;
        this.setDrawingDims();
        this.initSubscription.unsubscribe();

        if (this.showStartupDialog && !this.tourIsOn) {
          this.openStartDialog();
        }
      }
    }
  }

  get tourIsOn(): boolean {
    return this.tourService.getStatus() === 1;
  }

  get disableRedo(): boolean {
    return this.activeDrawing
      ? this.activeDrawing.getRedoHolderCount < 1
      : true;
  }

  get disableUndo(): boolean {
    return this.activeDrawing ? this.activeDrawing.pathCount < 1 : true;
  }

  get disabledZoomIn(): boolean {
    return this.activeDrawing ? this.activeDrawing.maxZommed : true;
  }

  get disabledZoomOut(): boolean {
    return this.activeDrawing ? this.activeDrawing.minZommed : true;
  }

  resetZoom(): void {
    this.activeDrawing?.resetZoom();
  }

  zoom(level: number): void {
    this.activeDrawing?.zoomToCenter(level);
  }

  moveHorizontal(left: boolean): void {
    this.activeDrawing?.move(left ? 25 : -25, 0);
  }

  moveVertical(up: boolean): void {
    this.activeDrawing?.move(0, up ? -25 : 25);
  }

  get templateBundle(): TemplateBundle {
    return this.settings.TemplateBundle;
  }

  get activeTemplate(): Template | undefined {
    return this.templateBundle.Templates.find((t) => t.show);
  }

  get activeDrawing(): CanvasComponent | undefined {
    if (!this.drawings || this.drawings.length < 1) {
      return undefined;
    }
    const activeDrawing = this.drawings
      .toArray()
      .find((d) => d.template === this.activeTemplate);
    if (activeDrawing) {
      return activeDrawing;
    } else {
      console.error('No active drawing');
      return undefined;
    }
  }

  get zoomLevel(): number | undefined {
    return this.activeDrawing?.zoomLevel;
  }

  get allEmptyDrawings(): boolean {
    return this.drawings
      .toArray()
      .map((x) => x.isDrawn)
      .every((x) => !x);
  }

  undo(): void {
    this.activeDrawing?.undo();
  }

  redo(): void {
    this.activeDrawing?.redo();
  }

  toggleOverlay(overlay: string): void {
    this._loadingService.register('app-draw.load');
    this.activeDrawing?.toggleOverlay(overlay, (error?: string) => {
      this._loadingService.resolve('app-draw.load');
      if (error) {
        // TODO: translate
        const message = overlay + ' overlay not found';
        this.snackBar.open(message, 'Dismiss');
        throw new Error(message + ' for: ' + error);
      }
    });
  }

  openSaveDialog() {
    this.saveDialogOpen = true;
    this._dialogService
      .open(this.saveDialog)
      .afterClosed()
      .subscribe((_) => (this.saveDialogOpen = false));
  }

  showSaveDialog() {
    this.infoMessage = undefined;
    if (!this.templateChanged) {
      this.warnFirstUse = true;
      this.showTemplateDialog();
    } else {
      this.noPain = this.allEmptyDrawings;
      this.openSaveDialog();
    }
  }

  showTemplateDialog(closeCallback?: () => void) {
    this.templateDrawings = this.drawings.map((x) => {
      return { Template: x.template, Chart: x.chartObject };
    });
    this.templateChanged = true;
    this._dialogService
      .open(this.templateDialog)
      .afterClosed()
      .subscribe(() => {
        this.templateDrawings = undefined;
        this.warnFirstUse = false;
        if (closeCallback) {
          closeCallback();
        }
      });
  }

  closeAllDialogs() {
    this._dialogService.closeAll();
  }

  setPainType(index: number) {
    this.painTypeIndex = index;
    if (
      this.painType.PainIntensities &&
      this.painType.PainIntensities.length < 2
    ) {
      this.painIntensityIndex = 0;
      this.closeAllDialogs();
    } else {
      this.painTypeSelected = true;
    }
  }

  getDefaultIntensity(intensities: PainIntensity[]): PainIntensity {
    return intensities[0];
  }

  setPainIntensityIndex(index: number) {
    this.painIntensityIndex = index;
    this.painTypeSelected = false;
    this.isDrawing = true;
    this.closeAllDialogs();
  }

  continue() {
    if (this.currentQuestionnaire && !this.currentQuestionnaireAnswered) {
      this.currentQuestionnaire.Invalid = true;
    } else if (this.isLastQuestionaire) {
      this.submitSubmission();
    } else {
      this.setQuestionnaireIndex(1);
    }
  }

  submitSubmission(): void {
    if (this.noPain) {
      this.closeAllDialogs();

      this.save.emit(this.defaultSubmission());
      return;
    }
    if (this.painLevel <= 0 && !this.infoMessage) {
      this.infoMessage = 'TOOLBOX.set_pain_intensity';
      return;
    }
    try {
      const submission: Submission = {
        PainLevel: this.painLevel,
        UsualPainLevel: this.usualPainLevel,
        Questionnaires: this.questionnaires,
        Charts: [],
        Note: this.note,
      };

      this.drawings.toArray().forEach((canvas) => {
        if (canvas.isDrawn) {
          const chart = <Chart>{};
          chart.Canvas = canvas.JSONString;
          chart.Height = canvas.getHeight();
          chart.Width = canvas.getWidth();
          chart.TemplateId = canvas.template.Id;
          if (this.settings.PatientId) {
            chart.PatientId = this.settings.PatientId;
          }
          submission.Charts.push(chart);
        }
      });

      this.closeAllDialogs();

      // Alert if charts are empty
      if (submission.Charts.length < 1) {
        // this should not hit after hasDrawnCharts check
        this.translate.get('MESSAGES').subscribe((messages) => {
          this.snackBar.open(
            messages.drawing_are_emtpy_nothing_saved,
            // messages.dismiss,
            undefined,
            { duration: 2500, panelClass: ['primary-green'] }
          );
          return;
        });
      } else {
        this.save.emit(submission);
      }
    } catch (error) {
      this.closeAllDialogs();
      // TODO: translate
      this.snackBar.open(
        'An error occurred, try restarting/refreshing the app and try again'
      );
    }
  }

  private defaultSubmission(): Submission {
    const chart = <Chart>{};
    this.drawings.toArray().forEach((canvas) => {
      // select default template (min template id)
      if (chart.TemplateId <= canvas.template.Id) {
        return;
      }
      chart.TemplateId = canvas.template.Id;
      chart.Canvas = canvas.JSONString;
      chart.Height = canvas.getHeight();
      chart.Width = canvas.getWidth();
    });

    const submission: Submission = {
      PainLevel: this.painLevel,
      UsualPainLevel: this.usualPainLevel,
      Questionnaires: this.questionnaires,
      Charts: [chart],
      NoPain: true,
      Note: this.note,
    };

    return submission;
  }

  setDrawingDims(): void {
    this.documentWidth = this.winRef.innerWidth;
    this.documentHeight = this.winRef.innerHeight;
    let h = this.element.nativeElement.parentElement.clientHeight;
    let w = this.element.nativeElement.parentElement.clientWidth;
    if (!h || h < 1) {
      h =
        this.documentWidth <= 600
          ? this.documentHeight - 56
          : this.documentHeight - 64;
    } else if (this.documentWidth <= 600) {
      h -= 8;
    }
    if (!w || w < 1) {
      w =
        this.documentWidth < 960 || this.router.url.startsWith('/draw/')
          ? this.documentWidth
          : this.documentWidth - 257; // remove sidebar width if small device or using single auth link
    }
    const padding = 18 * 2; // mat-card padding + canvas padding (both in top and bottom)
    w -= padding;
    h -= padding;

    this.setHeight(h);
    this.setWidth(w);
    setTimeout(() => {
      this.setWidth(w);
    }, 300);
  }

  setHeight(height: number): void {
    height = Math.max(350, height);
    if (this.drawingHeight !== height) {
      this.drawingHeight = height;
    }
  }

  setWidth(width: number): void {
    width = Math.max(200, width);
    if (this.drawingWidth !== width) {
      this.drawingWidth = width;
    }
  }

  showTemplate(template: Template, init: boolean = true) {
    if (init) {
      this.templateChanged = true;
    }
    // this.isDrawing = true;
    this.templateBundle.Templates.forEach((t) => {
      if (t === template) {
        t.show = true;
      } else {
        t.show = false;
      }
    });
    if (init) {
      this.closeAllDialogs();
      if (this.activeDrawing) {
        this.activeDrawing?.resetZoom();
      }
    }
  }

  nextTemplate(): void {
    const bundle = this.templateBundle;
    if (this.currentTemplateIndex === bundle.Templates.length - 1) {
      this.currentTemplateIndex = 0;
    } else {
      this.currentTemplateIndex++;
    }
    this.activeDrawing?.resetZoom();
    this.showTemplate(bundle.Templates[this.currentTemplateIndex]);
  }

  prevTemplate(): void {
    const bundle = this.templateBundle;
    if (this.currentTemplateIndex === 0) {
      this.currentTemplateIndex = bundle.Templates.length - 1;
    } else {
      this.currentTemplateIndex--;
    }
    this.activeDrawing?.resetZoom();
    this.showTemplate(bundle.Templates[this.currentTemplateIndex]);
  }

  isColorLight(color: string) {
    return this.colorBrightness(color) >= 145;
  }

  colorBrightness(hex: string): number {
    if (hex.startsWith('#')) {
      hex = hex.substring(1);
    }
    const rgb = parseInt(hex, 16);

    // tslint:disable:no-bitwise
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    return (r * 299 + g * 587 + b * 114) / 1000;
  }
}
