import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createClient, SupabaseClient, AuthSession, SupportedStorage } from '@supabase/supabase-js';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { JwtPayload, User } from '@models';

const USER_STORAGE_KEY = 'user';

@Injectable()
export class AuthService {
  private url = `${environment.api_path}api/v${environment.api_version}/Account/Auth`;
  private supabase: SupabaseClient;
  private sessionSubject = new BehaviorSubject<AuthSession | null>(null);

  get user(): User | undefined {
    return this.hasSession ? this.getUser() : undefined;
  }

  constructor(private http: HttpClient, private router: Router) {
    this.sessionSubject.subscribe(session => this.storeSession(localStorage, session));
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseAnonKey, 
      {detectSessionInUrl: false, autoRefreshToken: false, persistSession: false});

    // Initialize session
    let session = this.supabase.auth.session();
    if (!session) {
      // Look in localStorage for a session
      session = this.getStoredSession(localStorage);
      if (session) {
        this.setSession(session.access_token, session.refresh_token).then();
      }
    }
    this.sessionSubject.next(session);

    // Listen to auth state changes
    this.supabase.auth.onAuthStateChange((event, session) => {
      this.sessionSubject.next(session);
    });
  }

  getUser(): User {
    const payload = this.getJwtPayLoad();
    const user = new User(undefined);

    user.Id = payload?.sub ?? undefined;
    user.Email = payload?.email ?? undefined;
    user.rolesArray = payload?.roles ?? [];

    return user;
  }

  async sendMagicLink(email: string, redirectUrl: string) {
    return this.http.post<void>(this.url + '/MagicLink', {
      Email: email,
      RedirectUrl: redirectUrl,
      Host: window.location.origin
    }).toPromise();
  }

  async handleMagicLinkCallback(): Promise<string | null> {
    // Extract the redirect URL from query parameters
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirectUrl');
    if (this.hasSession) {
      return redirectUrl;
    }

    const { data, error } = await this.supabase.auth.getSessionFromUrl({ storeSession: false });
    if (error) throw error;
    if (!data) throw new Error('Something went wrong.');

    // Clear query parameters to clean up the URL
    window.history.replaceState({}, document.title, window.location.pathname);
    
    const session = await this.setSession(data.access_token, data.refresh_token);
    this.sessionSubject.next(session);

    return redirectUrl;
  }

  get hasSession() {
    return !!this.sessionSubject.value;
  }

  async signOut(redirect = true): Promise<void> {
    const { error } = await this.supabase.auth.signOut();
    this.sessionSubject.next(null);
    if (redirect) {
      this.router.navigateByUrl('/login');
    }
    if (error) { throw error; }
  }

  async setSession(access_token, refresh_token) {
    const {session, error } = await this.supabase.auth.setSession({ access_token, refresh_token });
    if (error) throw error;
    return session;
  }

  getAccessToken(): string | null {
    const session = this.supabase.auth.session() ?? this.sessionSubject.value;
    return session?.access_token || null;
  }

  refreshTokens(): Observable<string> {
    return from(
      this.supabase.auth.refreshSession().then(({ data, error }) => {
        if (error) { throw error; }
        if (data) {
          this.sessionSubject.next(data);
          return data.access_token;
        }
        throw new Error('Failed to refresh tokens');
      })
    );
  }

  getJwtPayLoad(): JwtPayload | undefined {
    const token = this.getAccessToken();
    if (!token) return undefined;
    try {
      return jwtDecode(token) as JwtPayload;
    } catch (e) {
      console.error('Error decoding token:', e);
      return undefined;
    }
  }

  getRoles(): string[] {
    const payload = this.getJwtPayLoad();
    return payload ? payload.roles : [];
  }
  
  storeSession(storage: SupportedStorage, session: AuthSession | null): void {
    if (session) {
      storage?.setItem(USER_STORAGE_KEY, JSON.stringify(session));
    }
  }

  getStoredSession(storage: SupportedStorage): AuthSession | null {
    const value = storage?.getItem(USER_STORAGE_KEY);
    if (!value || typeof value !== 'string') {
      return null;
    }
    try {
      return JSON.parse(value) as AuthSession;
    } catch {
      return null;
    }
  }
}
