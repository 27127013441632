<div
  id="fab-dismiss"
  *ngIf="clickToDismiss && active"
  (click)="onToggleFab()"
></div>
<div class="fab-container">
  <button mat-fab class="fab-toggler" color="primary" (click)="onToggleFab()">
    <mat-icon>{{ active ? 'close' : 'keyboard_arrow_left' }}</mat-icon>
  </button>
  <div [@fabStagger]="buttons.length">
    <button
      *ngFor="let btn of buttons"
      mat-mini-fab
      color="accent"
      (click)="onSelect.emit(btn.name)"
    >
      <mat-icon>{{ btn.icon }}</mat-icon>
    </button>
  </div>
</div>
