import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Schedule } from '@models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ScheduleService {
  url = `${environment.api_path}api/v${environment.api_version}/Schedules`;
  constructor(private http: HttpClient) {}

  putSchedule(schedule: Schedule) {
    return this.http.put<Schedule>(this.url + `/${schedule.Id}`, schedule);
  }

  postSchedule(schedule: Schedule) {
    return this.http.post<Schedule>(this.url, schedule);
  }

  deleteSchedule(scheduleId: string) {
    return this.http.delete<Schedule>(this.url + `/${scheduleId}`);
  }
}
