import { TourService } from 'ngx-tour-md-menu';
import { Component } from '@angular/core';

@Component({
  selector: 'app-tour-step',
  templateUrl: './tour-step.component.html',
  styles: [
    `
      .tour-card {
        padding: 0 !important;
        box-shadow: 0 0 !important;
      }
      .border-btn {
        margin-right: 10px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
      }
    `,
  ],
})
export class TourStepComponent {
  constructor(public tourService: TourService) {}
}
