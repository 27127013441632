<mat-expansion-panel class="app-expansion-panel" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ 'PATIENT.schedules' | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <mat-card-content
    style="padding: 0"
    *ngIf="
      patientId &&
      (activeSchedules().length > 0 || commingSchedules().length > 0)
    "
  >
    <mat-nav-list fxLayout="column">
      <ng-template
        let-schedule
        let-last="last"
        ngFor
        [ngForOf]="activeSchedules()"
      >
        <mat-list-item
          fxLayout="row"
          (click)="scheduleDialog.open(schedule, patientId)"
        >
          <h3 matLine>{{ schedule.Title }}</h3>
          <p matLine *ngIf="patientPortal">
            {{ 'SIGNUP.clinic_name' | translate }}:
            {{ schedule.OrganizationName }}
          </p>
          <p matLine *ngIf="!patientPortal && schedule.Description">
            {{ 'GLOBAL.description' | translate }}: {{ schedule.Description }}
          </p>
          <p matLine fxHide.gt-md class="mat-caption">
            {{ 'SCHEDULE.ends_in' | translate }}
            {{ schedule.EndDate | timeBetween: 'days' }}
            {{
              (schedule.EndDate | timeBetween: 'days') > 1
                ? ('TIMES.days' | translate)
                : ('TIMES.day' | translate)
            }}
          </p>
          <span fxFlex></span>
          <span fxFlex fxHide fxShow.gt-md>
            <div class="mat-caption tc-grey-500">
              {{ 'SCHEDULE.ends_in' | translate }}
              {{ schedule.EndDate | timeBetween: 'days' }}
              {{
                (schedule.EndDate | timeBetween: 'days') > 1
                  ? ('TIMES.days' | translate)
                  : ('TIMES.day' | translate)
              }}
            </div>
          </span>
          <mat-icon>navigate_next</mat-icon>
        </mat-list-item>
        <mat-divider
          *ngIf="!last || commingSchedules().length > 0"
        ></mat-divider>
      </ng-template>
      <ng-template
        let-schedule
        let-last="last"
        ngFor
        [ngForOf]="commingSchedules()"
      >
        <mat-list-item
          fxFlex
          fxLayout="row"
          (click)="scheduleDialog.open(schedule, patientId)"
        >
          <h3 matLine>{{ schedule.Title }}</h3>
          <p matLine *ngIf="patientPortal">
            {{ 'SIGNUP.clinic_name' | translate }}:
            {{ schedule.OrganizationName }}
          </p>
          <p matLine *ngIf="!patientPortal && schedule.Description">
            {{ 'GLOBAL.description' | translate }}: {{ schedule.Description }}
          </p>
          <span fxFlex></span>
          <p matLine fxHide.gt-md class="mat-caption">
            {{ 'SCHEDULE.starts_in' | translate }}
            {{ schedule.StartDate | timeBetween: 'days' }}
            {{
              (schedule.StartDate | timeBetween: 'days') > 1
                ? ('TIMES.days' | translate)
                : ('TIMES.day' | translate)
            }}
          </p>
          <span fxFlex fxHide fxShow.gt-md>
            <div class="mat-caption tc-grey-500">
              {{ 'SCHEDULE.starts_in' | translate }}
              {{ schedule.StartDate | timeBetween: 'days' }}
              {{
                (schedule.StartDate | timeBetween: 'days') > 1
                  ? ('TIMES.days' | translate)
                  : ('TIMES.day' | translate)
              }}
            </div>
          </span>
          <mat-icon>navigate_next</mat-icon>
        </mat-list-item>
        <mat-divider *ngIf="!last"></mat-divider>
      </ng-template>
    </mat-nav-list>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions *ngIf="!patientPortal">
    <div mat-dialog-actions fxLayout="row">
      <span fxFlex></span>
      <button
        mat-button
        color="primary"
        (click)="scheduleDialog.newSchedule(patientId)"
      >
        {{ 'PATIENT.new_schedule' | translate }}
      </button>
    </div>
  </mat-card-actions>
</mat-expansion-panel>
<app-schedule-dialog
  #scheduleDialog
  (new)="insertSchedule($event)"
  (change)="updateSchedule($event)"
  (delete)="deleteSchedule($event)"
  [disabled]="patientPortal"
></app-schedule-dialog>
