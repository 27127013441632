import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Organization, Patient, AuthToken, Settings } from '@models';
import { environment } from '@environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ResearchService {
  url = `${environment.api_path}api/v${environment.api_version}/Research`;
  constructor(private http: HttpClient) {}

  getOrganization(): Observable<Organization> {
    return this.http.get<Organization>(this.url + '/Personnel');
  }

  putOrganization(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(
      this.url + `/${organization.Id}`,
      organization
    );
  }

  postOrganization(organization: any): Observable<Organization> {
    return this.http.post<Organization>(this.url, organization);
  }

  /** Get patient for patientId, authorization: Personnel */
  getPatient(
    patientId: string,
    includeCharts = false,
    includeSubmissions = false
  ): Observable<Patient> {
    if (patientId === 'dummy-patient') {
      return this.http.get('/assets/data/demo_patient.json').pipe(
        map((p) => {
          const patient = <Patient>p;
          const currentDate = new Date();
          patient.Schedules.map((s) => (s.StartDate = currentDate));
          currentDate.setDate(currentDate.getDate() + 2);
          patient.Schedules.map((s) => (s.EndDate = currentDate));
          return patient;
        })
      );
    }

    const params = new HttpParams()
      .set('IncludeCharts', includeCharts.toString())
      .set('IncludeSubmissions', includeSubmissions.toString());

    return this.http.get<Patient>(this.url + `/Patient/${patientId}`, {
      params: params,
    });
  }

  /** Get patient for patientId, authorization: Personnel */
  getPatientDrawingSettings(patientId: string): Observable<Settings> {
    if (patientId === 'dummy-patient') {
      return this.http.get<Settings>(
        '/assets/data/demo_patient_drawingsettings.json'
      );
    }
    return this.http.get<Settings>(
      this.url + `/Patient/${patientId}/DrawingSettings`
    );
  }

  deletePatient(patientId: string) {
    return this.http.delete(this.url + `/Patient/${patientId}`);
  }

  /** Get patients for organization, authorization: Personnel */
  getPatients(includeCharts = false) {
    const params = new HttpParams().set(
      'IncludeCharts',
      includeCharts.toString()
    );

    return this.http.get<Patient[]>(this.url + '/Patients', { params: params });
  }

  getAuthToken(patient: Patient, delay: number): Observable<AuthToken> {
    const params = new HttpParams().set(
      'daysUntilExpiration',
      delay.toString()
    );
    return this.http.get<AuthToken>(
      this.url + `/Patient/${patient.Id}/AuthToken`,
      { params: params }
    );
  }

  revokeAuthToken(token: string): Observable<any> {
    return this.http.delete(this.url + `/AuthToken/${token}`);
  }

  requestEscalation(): Observable<any> {
    return this.http.post(this.url + '/Request/Escalation', []);
  }

  requestRenewal(): Observable<any> {
    return this.http.post(this.url + '/Request/Renewal', []);
  }

  requestFeatures(features: string[]) {
    return this.http.post(this.url + '/Request/Features', features);
  }

  requestExport(types: string[]) {
    return this.http.post(this.url + '/Request/Export', types);
  }
}
