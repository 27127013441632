<div fxLayout="column">
  <mat-card
    class="add-ons-card n"
    (click)="changeAddon('analytics')"
    [class.selected]="analyticAddon"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-checkbox
      fxHide.lt-md
      style="padding: 10px"
      [disabled]="true"
      [checked]="analyticAddon"
    ></mat-checkbox>
    <div
      fxFlex
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column">
        <span class="name">{{ 'PAYMENT.ADDONS.analytics' | translate }}</span>
        <span class="price"
          >€499<span class="period"
            >/&nbsp;{{ 'TIMES.year' | translate }}</span
          ></span
        >
      </div>
      <span fxFlex></span>
      <div fxFlex="45" fxFlex.sm="100" class="features" fxLayout="column">
        <mat-list class="feature-list">
          <mat-list-item
            [matTooltip]="'PAYMENT.ADDONS.sub_calcs_tooltip' | translate"
          >
            &nbsp;-&nbsp;&nbsp;<span translate>PAYMENT.ADDONS.sub_calcs</span>
            &nbsp;(<span translate>GLOBAL.comming_soon</span>)</mat-list-item
          >

          <mat-list-item
            [matTooltip]="'PAYMENT.ADDONS.bulk_png_tooltip' | translate"
          >
            &nbsp;-&nbsp;&nbsp;<span translate>PAYMENT.ADDONS.bulk_png</span>
          </mat-list-item>

          <mat-list-item
            [matTooltip]="'PAYMENT.ADDONS.heatmap_tooltip' | translate"
          >
            &nbsp;-&nbsp;&nbsp;<span translate>PAYMENT.ADDONS.heatmap</span>
            &nbsp;(<span translate>GLOBAL.comming_soon</span>)</mat-list-item
          >
        </mat-list>
      </div>
    </div>
  </mat-card>
  <mat-card
    class="add-ons-card n"
    (click)="changeAddon('remote')"
    [class.selected]="remoteAddon"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-checkbox
      fxHide.lt-md
      style="padding: 10px"
      [disabled]="true"
      [checked]="remoteAddon"
    ></mat-checkbox>
    <div
      fxFlex
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column">
        <span class="name">{{ 'PAYMENT.ADDONS.remote' | translate }}</span>
        <span class="price"
          >€499<span class="period"
            >/&nbsp;{{ 'TIMES.year' | translate }}</span
          ></span
        >
      </div>
      <span fxFlex></span>
      <div fxFlex="45" fxFlex.sm="100" class="features" fxLayout="column">
        <mat-list class="feature-list">
          <mat-list-item
            [matTooltip]="'PAYMENT.ADDONS.remote_chart_tooltip' | translate"
          >
            &nbsp;-&nbsp;&nbsp;<span translate
              >PAYMENT.ADDONS.remote_chart</span
            ></mat-list-item
          >

          <mat-list-item
            [matTooltip]="'PAYMENT.ADDONS.email_scheduler_tooltip' | translate"
          >
            &nbsp;-&nbsp;&nbsp;<span translate
              >PAYMENT.ADDONS.email_scheduler</span
            ></mat-list-item
          >

          <mat-list-item
            [matTooltip]="'PAYMENT.ADDONS.single_link_tooltip' | translate"
          >
            &nbsp;-&nbsp;&nbsp;<span translate
              >PAYMENT.ADDONS.single_link</span
            ></mat-list-item
          >
        </mat-list>
      </div>
    </div>
  </mat-card>
  <mat-card
    class="add-ons-card"
    (click)="changeAddon('both')"
    [class.selected]="bothAddons"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-checkbox
      fxHide.lt-md
      [disabled]="true"
      [checked]="bothAddons"
      style="padding: 10px"
    ></mat-checkbox>
    <div fxLayout="column">
      <span class="name"
        >{{ 'PAYMENT.ADDONS.analytics' | translate }} +
        {{ 'PAYMENT.ADDONS.remote' | translate }}</span
      >
      <span class="price"
        >€749<span class="period"
          >/&nbsp;{{ 'TIMES.year' | translate }}</span
        ></span
      >
    </div>
  </mat-card>
</div>
