import { Component, Input } from '@angular/core';
import { Questionnaire } from '@models';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent {
  @Input() questionnaire!: Questionnaire;

  invalid(question: any): boolean {
    return (
      this.questionnaire.Invalid &&
      question.Required &&
      question.Answer == undefined
    );
  }
}
