<td-dialog>
  <td-dialog-title>
    {{ 'DIALOGS.start_tour.title' | translate }}
  </td-dialog-title>
  <td-dialog-content>
    {{ 'DIALOGS.start_tour.message' | translate }}
    <div *ngIf="showSkip">
      <span fxFlex></span>
      <mat-checkbox
        [(ngModel)]="dontShow"
        style="margin-top: 25px"
        color="primary"
        labelPosition="before"
        >{{ 'DIALOGS.start_tour.dontShowAgain' | translate }}</mat-checkbox
      >
    </div>
  </td-dialog-content>
  <td-dialog-actions>
    <span fxFlex></span>
    <button color="primary" (click)="skip()" mat-button>
      {{ 'DIALOGS.start_tour.cancelButton' | translate }}
    </button>
    <button color="primary" (click)="dialogRef.close(true)" mat-raised-button>
      {{ 'DIALOGS.start_tour.acceptButton' | translate }}
    </button>
  </td-dialog-actions>
</td-dialog>
