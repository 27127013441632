import { User } from './User';
export class Personnel {
  Id?: string;
  Fullname?: string;
  Email?: string;
  LanguageLocale?: string;
  Timezone?: string;
  IsManager?: boolean;

  constructor(user?: User) {
    if (!user) {
      user = <User>{};
    }
    this.Id = user.Id;
    this.Fullname = user.Fullname;
    this.Email = user.Email;
    this.LanguageLocale = user.LanguageLocale;
    this.Timezone = user.Timezone;
    this.IsManager = user?.isResearchManager;
  }
}
