import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyPipe',
})
export class AppCurrencyPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (typeof value !== 'string') {
      return 'N/A';
    }
    switch (value.toUpperCase()) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 'AUD':
        return 'A$';
      case 'CAD':
        return 'C$';
      case 'CHF':
        return 'Fr';
      case 'MXN':
        return 'Mex$';
      case 'BRL':
        return 'R$';
      default:
        return value.toUpperCase();
    }
  }
}
