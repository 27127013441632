<div
  fxLayout="row"
  (pan)="drag($event)"
  (pinch)="pinch($event)"
  fxFlexAlign="center center"
>
  <p class="unselectable template-title">
    {{ 'CANVAS.' + template.Name | translate }}
  </p>
  <canvas fxFlexAlign="center" #canvas></canvas>
</div>
