<p style="text-align: right">Last updated: January 7, 2019</p>
<h1 style="text-align: center">Terms of Service</h1>
<p>
  <span
    >Thanks for using our services (“Services”). These Services are provided by
    Aglance Solutions ApS (“Aglance”, “us”, “we”, or “our”), currently located
    at Tornhøjparken 25, 9220 Aalborg Ø, Denmark.</span
  >
</p>
<p>
  <span
    >To use the Services provided by Aglance, you must agree to the following
    Terms of Service (“Terms”), therefore please read them carefully before
    using the Services. The consent given to use our Services is always
    deniable, and can be withdrawn the same place as it is given. By denying the
    consent you can no longer use our Services.</span
  >
</p>
<p>
  <span
    >Please read these Terms of Service ("Terms", "Terms of Service") carefully
    before using the Navigate Pain app (the "Service") operated by Aglance
    Solutions ApS (“Aglance”, "us", "we", or "our").</span
  >
</p>
<p>
  <span
    >Your access to and use of the Service is conditioned on your acceptance of
    and compliance with these Terms. These Terms apply to all visitors, users
    and others who access or use the Service.</span
  >
</p>
<p>
  <span
    >By accessing or using the Service you agree to be bound by these Terms. If
    you disagree with any part of the Terms, then you may not access the
    Service.</span
  >
</p>
<h2><span>Professional medical usage</span></h2>
<p>
  <span
    >As for now our software Navigate Pain is not CE-marked device and therefore
    should be used according to the intended purposes as a communication tool to
    track and report pain and discomfort. &nbsp;
  </span>
</p>
<h2><span>Accounts</span></h2>
<p>
  <span
    >When you create an account, you must provide accurate information that is
    complete and current always. Failure to do so constitutes a breach of the
    Terms, which may result in immediate termination of your account and/or
    Service.</span
  >
</p>
<p>
  <span
    >You are responsible for safeguarding the password that you use to access
    the Service and for any activities or actions under your password, whether
    your password is with our Service or a third-party service.</span
  >
</p>
<p>
  <span
    >You agree not to disclose your password to any third party. You must notify
    us immediately upon becoming aware of any breach of security or unauthorized
    use of your account.</span
  >
</p>
<p>
  <span
    >You may not use as a username of another person or entity or that is not
    lawfully available for use, a name or trademark that is subject to any
    rights of another person or entity other than you without appropriate
    authorization, or a name that is otherwise offensive, vulgar or
    obscene.</span
  >
</p>
<h2><span>Underage Users</span></h2>
<p>
  <span
    >The Service is usable for all ages, but to agree with these Terms you must
    be at the age of 16 or older. It is possible for the guardians of under-aged
    users to accept the Terms on behalf of the under-aged user.</span
  >
</p>
<h2><span>Data Ownership</span></h2>
<p>
  <span
    >You retain ownership and responsibility of your personal information (see
    Privacy Policy for more information on what is personal information).</span
  >
</p>
<p>
  <span
    >Aglance reserves the right to retain an anonymous copy of any submitted
    pain reports including but not limited to the pain drawings, pain intensity
    ratings and annotation notes, and user data upon submission of new
    pain-drawings. Because of these ownership terms we need you to grant us
    certain legal permissions (listed below in License Grant to Aglance). These
    license grants apply to any pain reports submitted to our Services and a
    copy of anonymous data of the submitter.</span
  >
</p>
<h2><span>License Grant to Aglance</span></h2>
<p>
  <span>To use submitted data for our </span
  ><span>testing, exploration and analysis</span
  ><span
    >&nbsp;and other undetermined purposes we need the legal rights to copy any
    submitted pain reports. You grant Aglance and our legal successors the legal
    ownership of copies of any submitted pain reports with accompanying data as
    well as ownership of an anonymized version of the submitted personal data.
    The anonymized data includes the following: Birth Month, Birth Year and
    Gender.</span
  >
</p>
<p>
  <span
    >This license does also grant Aglance the right to use these anonymized data
    and pain-drawings for commercial purposes.</span
  >
</p>
<h2><span>Data location</span></h2>
<p><span>Our Services stores data inside EU.</span></p>
<p>
  <span
    >As our Services are accessible worldwide we reserve the right to safely
    store your personal information in the following countries outside of the
    EU:</span
  >
</p>
<ul>
  <li><span>Australia</span></li>
  <li><span>United States of America (USA)</span></li>
</ul>
<p>
  <span
    >By agreeing to these Terms, you give us the consent to safely store your
    personal information in the above listed countries.</span
  >
</p>
<h2><span>Links to Other Web Sites</span></h2>
<p>
  <span
    >Our Service may contain links to third-party web sites or services that are
    not owned or controlled by Aglance.</span
  >
</p>
<p>
  <span
    >Aglance has no control over, and assumes no responsibility for, the
    content, privacy policies, or practices of any third-party web sites or
    services. You further acknowledge and agree that Aglance shall not be
    responsible or liable, directly or indirectly, for any damage or loss caused
    or alleged to be caused by or in connection with use of or reliance on any
    such content, goods or services available on or through any such web sites
    or services.</span
  >
</p>
<p>
  <span
    >We strongly advise you to read the Terms of Service and privacy policies of
    any third-party web sites or services that you visit.</span
  >
</p>
<h2><span>Termination</span></h2>
<p>
  <span
    >We may terminate or suspend your account immediately, without prior notice
    or liability, if you violate our Terms of Service or otherwise abuse our
    services (without limitation if you breach the Terms).</span
  >
</p>
<p>
  <span
    >Upon termination, your right to use the Service will immediately cease. If
    you wish to terminate your account, you may simply discontinue using the
    Service by contacting customer service.</span
  >
</p>
<h2><span>Abuse and hacking</span></h2>
<p>
  <span
    >We reserve the right to remove access to the service upon discovery of
    abuse or misuse of the services such as hacking, hacking attempts, </span
  ><span>or disclosing account passwords with a third party</span
  ><span
    >, even for paid subscriptions. We will reach out to the excluded user via
    the account email to explain the reason for the exclusion and to handle
    possible misunderstanding.</span
  >
</p>
<p>
  <span
    >If your account is hacked or compromised in any way (e.g. your password is
    obtained by another) you must contact us (read Contact Us) to prevent any
    harm to the Service or your personal information contained therein.</span
  >
</p>
<h2><span>Limitation of Liability</span></h2>
<p>
  <span
    >In no event shall Aglance, nor its directors, employees, partners, agents,
    suppliers, or affiliates, be liable for any indirect, incidental, special,
    consequential or punitive damages, including without limitation, loss of
    profits, data, use, goodwill, or other intangible losses, resulting from (i)
    your access to or use of or inability to access or use the Service; (ii) any
    conduct or content of any third party on the Service; (iii) any content
    obtained from the Service; and (iv) unauthorized access, use or alteration
    of your transmissions or content, whether based on warranty, contract, tort
    (including negligence) or any other legal theory, whether or not we have
    been informed of the possibility of such damage, and even if a remedy set
    forth herein is found to have failed of its essential purpose.</span
  >
</p>
<h2><span>Disclaimer</span></h2>
<p>
  <span
    >Your use of the Service is at your sole risk. The Service is provided on an
    "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties
    of any kind, whether express or implied, including, but not limited to,
    implied warranties of merchantability, fitness for a particular purpose,
    non-infringement or course of performance.</span
  >
</p>
<p>
  <span
    >Aglance, its subsidiaries, affiliates, and its licensors do not warrant
    that a) the Service will function uninterrupted</span
  ><span>&nbsp;or available</span
  ><span
    >&nbsp;at any particular time or location; b) any errors or defects will be
    corrected; c) the Service is free of viruses or other harmful components; or
    d) the results of using the Service will meet your requirements.</span
  >
</p>
<h2><span>Governing Law</span></h2>
<p>
  <span
    >These Terms shall be governed and construed in accordance with the laws of
    Denmark, without regard to its conflict of law provisions.</span
  >
</p>
<p>
  <span
    >Our failure to enforce any right or provision of these Terms will not be
    considered a waiver of those rights. If any provision of these Terms is held
    to be invalid or unenforceable by a court, the remaining provisions of these
    Terms will remain in effect. These Terms constitute the entire agreement
    between us regarding our Service and supersede and replace any prior
    agreements we might have between us regarding the Service.</span
  >
</p>
<h2><span>Changes</span></h2>
<p>
  <span
    >We reserve the right, at our sole discretion, to modify or replace these
    Terms at any time. What constitutes a material change will be determined at
    our sole discretion.</span
  >
</p>
<p>
  <span
    >You will be asked to accept the Terms after the revision is applied. If you
    do not accept the new Terms, you will not be granted access to the
    Service.</span
  >
</p>
<h2><span>Contact Us</span></h2>
<p>
  <span
    >If you have any questions about these Terms, please contact us at
    support@aglancesolutions.com.</span
  >
</p>
<p><span>&nbsp;</span></p>
<p><span>&nbsp;</span></p>
<h1><span>Privacy policy</span></h1>
<p>
  <span
    >This privacy policy ("Policy") describes how we collect, protect and use
    the personally identifiable information ("Personal Information") you
    ("User", "you" or "your") provide on any of the products or services
    ("Services") owned and operated by Aglance Solutions ApS ("Aglance", "us",
    "we" or "our"). It also describes the choices available to you regarding our
    use of your personal information and how you can access and update this
    information. This Policy does not apply to the practices of companies that
    we do not own or control, or to individuals that we do not employ or
    manage.</span
  >
</p>
<h2><span>Collection of personal information</span></h2>
<p>
  <span
    >We receive and store any information you knowingly provide to us when you
    create an account, make a purchase, or fill any online form on the Services.
    Or we receive and store any information about you provided by other data
    processors (e.g. physiotherapy clinic personnel).</span
  >
</p>
<p>
  <span>We collect, store and use the following personal information:</span>
</p>
<ul>
  <li>
    <b>Full name</b
    ><span
      >&nbsp;is used for identification purposes, e.g. when a data processor
      need to find you in our Services.</span
    >
  </li>
  <li>
    <b>Email</b
    ><span
      >&nbsp;is used for identification purposes when the user logs into our
      Services and as a primary contact between our Services and the
      users.</span
    >
  </li>
  <li>
    <b>Birthdate</b
    ><span
      >&nbsp;is used to provide information about your age to a data
      processor.</span
    >
  </li>
  <li>
    <b>Gender</b
    ><span>&nbsp;is used to provide your gender to a data processor.</span>
  </li>
  <li>
    <b>Weight</b
    ><span
      >&nbsp;(optional) is used to provide information about your weight to a
      data processor.</span
    >
  </li>
  <li>
    <b>Height</b
    ><span
      >&nbsp;(optional) is used to provide information about your height to a
      data processor.</span
    >
  </li>
  <li>
    <b>Payment details</b
    ><span
      >&nbsp;is stored to ensure the customer has the right access right in our
      Services.
    </span>
  </li>
  <li>
    <b>Pain reports and charts</b
    ><span
      >&nbsp;is the core data of our Services and is used to visualize and
      document pain.</span
    >
  </li>
</ul>
<ul>
  <li>
    <b>Body drawings</b
    ><span>&nbsp;reflecting the patients pain on a human body template.</span>
  </li>
  <li>
    <b>Pain levels</b
    ><span
      >&nbsp;of the usual and current pain levels are stored together with each
      chart submission.</span
    >
  </li>
  <li>
    <b>Pain note</b
    ><span
      >&nbsp;an annotation note for additional explanation of the pain
      drawing.</span
    >
  </li>
</ul>
<p>
  <span
    >In order to provide our Services, we need to collect personal information.
    To know more about how personal information are stored refer to
  </span>
  <b>Automatic deletion of personal information</b><span>.</span>
</p>
<h2><span>Collection of non-personal information</span></h2>
<p>
  <span
    >When you use our Services, information that your browser sends are
    automatically obtained to enrich and improve our Services. This data may
    include information such as your devices IP address, browser type and
    version, operating system type and version, language preferences or the
    webpage you were visiting before you came to our Services, pages of our
    Services that you visit, the time spent on those pages, information you
    search for in our Services, access times and dates, and other
    statistics.</span
  >
</p>
<p>
  <span
    >We also collect, store and use the following non-personal
    information:</span
  >
</p>
<ul>
  <li>
    <b>Language</b
    ><span
      >&nbsp;information is used to show the best corresponding translated
      versions of our Services.</span
    >
  </li>
  <li>
    <b>Time zone</b
    ><span
      >&nbsp;information is used to show the correct time given the stored time
      zone.</span
    >
  </li>
  <li>
    <span>Pop-up questionnaires or Polls </span
    ><span
      >to help us understand your interests and impressions regarding current or
      potentially new features, functions, applications and usability
      experiences pertaining to our Services.</span
    >
  </li>
</ul>
<p>
  <span
    >We also reserve the right to store logging information such as timestamps
    of creation, altering or deletion or request of any data.</span
  >
</p>
<h2><span>Use of collected information</span></h2>
<p>
  <span
    >Any of the information we collect from you may be used to process
    transactions; run and operate our Services in ways as described above.
    Non-personal information collected may be used to identify potential cases
    of abuse, to establish statistical information regarding internet traffic
    and usage, and for research and data-mining purposes. This statistical
    information is not otherwise aggregated in such a way that would identify
    any particular user of the system.</span
  >
</p>
<h2><span>Managing personal information</span></h2>
<p>
  <span
    >You are able to access, add to, update and delete certain Personal
    Information about you, directly in our Services. Other personal information
    may be restricted from altering/deletion in our Services and therefore a
    request for altering/deletion this information must be sent to us (see
    Contacting us) to be updated. The information you can view, update, and
    delete may change as the Services change. When you update information,
    however, we may maintain a copy of the unrevised information in our records.
    We will retain your information for as long as your account is active or as
    needed to provide you Services. Some information may remain in our private
    records after your deletion of such information from your account for up to
    10 years of inactivity, hereafter all personal information data will
    automatically be deleted, you are always able to request immediately
    deletion of all your personal information be contacting us. We will retain
    and use your information as necessary to comply with our legal obligations,
    resolve disputes, and enforce our agreements. We may use any aggregated data
    derived from or incorporating your Personal Information after you update or
    delete it, but not in a manner that would identify you personally.</span
  >
</p>
<p><span>Automatic deletion of personal information</span></p>
<p>
  <span
    >All personal information will automatically be deleted on users after 10
    years of inactivity. A user is classified as inactive for 10 years if no new
    data is submitted, no payments are received or the users has not logged into
    our Services for 10 years. The timespan of 10 years is chosen due to the
    importance of the information we store and provide. The timespan period can
    be altered on request (by contacting us) as the owner of the personal
    information.</span
  >
</p>
<h2><span>Consent for handling personal information</span></h2>
<p>
  <span
    >If you have granted us consent to contact you by email (e.g. signing up for
    newsletters or notifications for blog posts) we store and handle the
    information provided by you until it is no longer relevant. In practice,
    your information will remain until you let us know that you no longer want
    to receive emails from us.</span
  >
</p>
<p>
  <span
    >You can always withdraw your consent. You can either do this by using the
    link at the bottom of our emails or by contacting us at
    support@aglancesolutions.com and informing us that you no longer wish to
    receive our emails.</span
  >
</p>
<h2><span>Billing and payments</span></h2>
<p>
  <span
    >In case of services requiring payment, we can request credit card
    information, which will be used solely for processing payments. We do not
    store any credit card information we solely provide the credit card
    information to Stripe, which then handles the payment and transaction
    process. Your purchase transaction data is stored in Stripes systems.
    Sensitive and private data exchange happens over a SSL secured communication
    channel and is encrypted and protected with digital signatures using the
    HTTPS protocol. Payment receipts are kept in our accounting records as
    proof-of-payment and used for accounting purposes.</span
  >
</p>
<h2><span>Children</span></h2>
<p>
  <span
    >We do not knowingly or aim to collect any personal information from
    children under the age of 16, without approval from the child’s guardian. If
    you are under the age of 16, please do not submit any personal information
    through our Services. We encourage parents and legal guardians to monitor
    their children's Internet usage and to help enforce this Policy by
    instructing their children never to provide personal information through our
    Services without their permission. If you have reason to believe that a
    child under the age of 16 has provided personal information to us through
    our Services, please contact us.</span
  >
</p>
<h2><span>Cookies etc.</span></h2>
<p>
  <span
    >Our Services uses Cookies (and other local storage techniques) provided by
    your device or browse. Cookies etc. are small files that generated on the
    device using our Services to improve the user experience of the Service.
    Cookies etc. cannot be used to run programs or deliver viruses to your
    device. Cookies etc. are uniquely assigned to you and can only be read by a
    web server in the domain that issued the cookie to you. We may use cookies
    etc. to collect, store, and track information for statistical purposes to
    operate our Services. You can block cookies from our Services, but we
    suggest accepting the use of cookies etc. for you to get the best user
    experience using our Services. Most web browsers automatically accept
    cookies, but you can usually modify your browser setting to block cookies
    etc. if you prefer.</span
  >
</p>
<h2><span>Data security</span></h2>
<p>
  <span
    >We secure any information you provide on servers and databases in a
    controlled, secure environment, protected from unauthorized access, use, or
    disclosure. We maintain reasonable administrative, technical, and physical
    safeguards in an effort to protect against unauthorized access, use,
    modification, and disclosure of personal information in its control and
    custody. We will always treat your personal information safely and
    confidently in accordance with applicable law, including the Personal Data
    Regulation and Data Protection Act.</span
  >
</p>
<p>
  <span
    >Your information will be used solely for the purpose for which they are
    collected and will be deleted when this purpose is fulfilled or no longer
    relevant.</span
  >
</p>
<p>
  <span
    >We have ensured all of these providers are compliant with EU GDPR, which is
    our guarantee that they comply with the applicable rules for the protection
    of your personal data.</span
  >
</p>
<h2><span>Data breach</span></h2>
<p>
  <span
    >In the event we become aware that the security of the Services has been
    compromised or users Personal Information has been disclosed to unrelated
    third parties as a result of external activity, including, but not limited
    to, security attacks or fraud, we reserve the right to take reasonably
    appropriate measures, including, but not limited to, investigation and
    reporting, as well as notification to and cooperation with law enforcement
    authorities. In the event of a data breach we will notify the Danish Data
    Protection Agency (Datatilsynet) within 72 hours of the discovery of the
    breach. Also in the event of a data breach, we will make reasonable efforts
    to notify affected individuals if we believe that there is a reasonable risk
    of harm to the user as a result of the breach or if notice is otherwise
    required by law. When we do we will post a notice on the Services and/or
    send you an email.</span
  >
</p>
<h2><span>Legal disclosure</span></h2>
<p>
  <span
    >In the event we go through a business transition, such as a merger or
    acquisition by another company, or sale of all or a portion of its assets,
    your user account and personal data will likely be among the assets
    transferred.</span
  >
</p>
<h2><span>Complaint</span></h2>
<p>
  <span
    >You always have the right to file a complaint to the Danish Data Protection
    Agency (Datatilsynet):</span
  >
</p>
<p><span>Address: Borgergade 28,5., 1300 København (Denmark)</span></p>
<p><span>Email: dt@datatilsynet.dk</span></p>
<p><span>Phone number: +45 33 19 32 00</span></p>
<p>
  <span
    >However, we strongly suggest contacting us prior to filing a complaint for
    further help, explanation, and timely resolve of any potential issue.</span
  >
</p>
<h2><span>Contacting us</span></h2>
<p>
  <span
    >If you have any questions about these Terms, please contact us at
    support@aglancesolutions.com.</span
  >
</p>
