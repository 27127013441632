<div class="mat-content" class="inset">
  <mat-card fxFlexAlign="center">
    <ng-template
      tdLoading="app-draw.load"
      [tdLoadingUntil]="settings"
      tdLoadingStrategy="overlay"
    >
      <div tourAnchor="app.canvas"></div>
      <button
        mat-icon-button
        class="template-control"
        style="left: 0"
        (click)="prevTemplate()"
        angulartics2On="click"
        angularticsCategory="Drawing"
        angularticsAction="PreviousTemplate"
        angularticsLabel="click"
      >
        <mat-icon color="primary">skip_previous</mat-icon>
      </button>
      <button
        mat-icon-button
        class="template-control"
        style="right: 0"
        (click)="nextTemplate()"
        tourAnchor="nextTemplate.button"
        angulartics2On="click"
        angularticsCategory="Drawing"
        angularticsAction="NextTemplate"
        angularticsLabel="click"
      >
        <mat-icon color="primary">skip_next</mat-icon>
      </button>
      <div
        class="zoom-controls left"
        style="margin-bottom: 135px"
        fxLayout="column"
      >
        <div [fxHide]="drawingHeight < 400" class="group" fxLayout="column">
          <button
            mat-icon-button
            (click)="redo()"
            [disabled]="disableRedo"
            tourAnchor="redo.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="Redo"
            angularticsLabel="click"
          >
            <mat-icon>redo</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="undo()"
            [disabled]="disableUndo"
            tourAnchor="undo.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="Undo"
            angularticsLabel="click"
          >
            <mat-icon>undo</mat-icon>
          </button>
        </div>
        <div class="group" fxLayout="column">
          <button
            mat-icon-button
            (click)="resetZoom()"
            tourAnchor="resetZoom.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="ResetZoom"
            angularticsLabel="click"
          >
            <mat-icon>fullscreen</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="zoom(1.15)"
            [disabled]="disabledZoomIn"
            tourAnchor="zoomIn.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="ZoomIn"
            angularticsLabel="click"
            [angularticsValue]="zoomLevel"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="zoom(0.85)"
            [disabled]="disabledZoomOut"
            tourAnchor="zoomOut.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="ZoomOut"
            angularticsLabel="click"
            [angularticsValue]="zoomLevel"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </div>
      <div class="zoom-controls left" fxLayout="column">
        <div class="btn-border" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="center">
            <div class="t-l-border-radius t-r-border-radius">
              <button
                mat-icon-button
                (click)="moveVertical(true)"
                angulartics2On="click"
                angularticsCategory="Drawing"
                angularticsAction="MoveUp"
                angularticsLabel="click"
              >
                <mat-icon>arrow_upward</mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row">
            <div class="t-l-border-radius b-l-border-radius">
              <button
                mat-icon-button
                (click)="moveHorizontal(false)"
                angulartics2On="click"
                angularticsCategory="Drawing"
                angularticsAction="MoveLeft"
                angularticsLabel="click"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
            </div>
            <div class="t-r-border-radius b-r-border-radius">
              <button
                mat-icon-button
                (click)="moveHorizontal(true)"
                angulartics2On="click"
                angularticsCategory="Drawing"
                angularticsAction="MoveRight"
                angularticsLabel="click"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center">
            <div class="b-l-border-radius b-r-border-radius">
              <button
                mat-icon-button
                (click)="moveVertical(false)"
                angulartics2On="click"
                angularticsCategory="Drawing"
                angularticsAction="MoveDown"
                angularticsLabel="click"
              >
                <mat-icon>arrow_downward</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="zoom-controls right" fxLayout="column">
        <div class="group" fxLayout="column">
          <button
            *ngIf="overlays && overlays.length"
            mat-icon-button
            [matMenuTriggerFor]="overlayMenu"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="OpenGrid"
            angularticsLabel="click"
          >
            <mat-icon>grid_on</mat-icon>
          </button>
          <button
            mat-icon-button
            [matMenuTriggerFor]="penSizeMenu"
            *ngIf="drawingHeight < 475"
            tourAnchor="penSize.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="OpenPenSizeMenu"
            angularticsLabel="click"
          >
            <mat-icon>adjust</mat-icon>
          </button>
        </div>
        <div
          class="group"
          fxLayout="column"
          tourAnchor="penSize.button"
          *ngIf="drawingHeight >= 475"
        >
          <ng-template let-s let-last="last" ngFor [ngForOf]="penSizes">
            <div class="selectable-btn" [class.selected]="penSize === s">
              <button
                mat-icon-button
                (click)="penSize = s; isDrawing = true"
                angulartics2On="click"
                angularticsCategory="Drawing"
                angularticsAction="PenSize"
                angularticsLabel="click"
                [angularticsValue]="s"
              >
                <div
                  fxFlexAlign="center"
                  class="circle"
                  [style.width]="s + 5 + 'px'"
                  [style.height]="s + 5 + 'px'"
                  [ngStyle]="{ 'background-color': painIntensity.Color }"
                ></div>
              </button>
            </div>
          </ng-template>
        </div>
        <div class="group" fxLayout="column">
          <button
            *ngIf="painTypes && painTypes.length > 1"
            mat-icon-button
            (click)="openPainTypeDialog()"
            tourAnchor="painType.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="OpenPainTypeDialog"
            angularticsLabel="click"
          >
            <mat-icon>palette</mat-icon>
          </button>
          <div
            *ngIf="painType && painType.PainIntensities.length > 1"
            fxLayout="column"
          >
            <ng-template
              let-intensity
              let-i="index"
              ngFor
              [ngForOf]="painType.PainIntensities"
            >
              <div
                class="selectable-btn"
                [class.selected]="painIntensityIndex === i"
              >
                <button
                  mat-icon-button
                  (click)="setPainIntensityIndex(i)"
                  angulartics2On="click"
                  angularticsCategory="Drawing"
                  angularticsAction="SelectIntensity"
                  angularticsLabel="click"
                  [angularticsValue]="painType.Name + '|' + intensity.Severity"
                >
                  <div
                    class="colorbox"
                    [style.background]="intensity.Color"
                    [matTooltip]="intensity.Severity"
                    matTooltipPosition="left"
                  >
                    <p
                      class="severity-text"
                      [ngClass]="{
                        'black-text': isColorLight(intensity.Color)
                      }"
                    >
                      {{ intensity.RangeStart }}-{{ intensity.RangeEnd }}
                    </p>
                  </div>
                </button>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="group" fxLayout="column">
          <button
            mat-icon-button
            (click)="showTemplateDialog()"
            tourAnchor="templates.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="OpenTemplateDialog"
            angularticsLabel="click"
          >
            <mat-icon>burst_mode</mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            (click)="showSaveDialog()"
            tourAnchor="save.button"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="OpenSaveDialog"
            angularticsLabel="click"
          >
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="settings">
        <app-canvas
          #drawings
          *ngFor="let t of templates"
          [hidden]="!t.show"
          [color]="painIntensity.Color"
          [template]="t"
          [penSize]="penSize"
          [isDrawing]="isDrawing"
          [height]="drawingHeight"
          [width]="drawingWidth"
        >
        </app-canvas>
      </div>
    </ng-template>
  </mat-card>
</div>
<ng-template #saveDialog>
  <td-dialog>
    <td-dialog-title>
      <button
        *ngIf="questionnaires.length"
        mat-mini-fab
        [disabled]="disablePrevQuestionnaire"
        color="white"
        (click)="setQuestionnaireIndex(-1)"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <span fxFlex *ngIf="questionnaires.length"></span>
      <div style="margin: auto; text-align: center">
        {{
          (currentQuestionnaire
            ? currentQuestionnaire.Name
            : 'TOOLBOX.submit_charts'
          ) | translate
        }}
        <span *ngIf="questionnaires.length"
          >({{ questionnaireIndex + 2 }} /
          {{ questionnaires.length + 1 }})</span
        >
      </div>
      <span fxFlex></span>
      <button
        *ngIf="questionnaires.length"
        mat-mini-fab
        [disabled]="disableNextQuestionnaire"
        color="white"
        (click)="setQuestionnaireIndex(1)"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </td-dialog-title>
    <ng-template #questionnaireElem>
      <td-dialog-content fxLayout="column" fxLayoutGap="16px">
        <app-questionnaire
          [questionnaire]="currentQuestionnaire"
        ></app-questionnaire>
      </td-dialog-content>
    </ng-template>
    <td-dialog-content *ngIf="!currentQuestionnaire; else questionnaireElem">
      <td-message
        *ngIf="noPain"
        color="orange"
        icon="warning"
        [sublabel]="'TOOLBOX.empty_drawings' | translate"
      >
      </td-message>
      <p>
        <span
          style="margin: auto"
          [innerHTML]="'TOOLBOX.rate_current_pain' | translate"
        ></span>
        <span fxFlex fxLayoutAlign="end end" class="pain-level-value">
          {{ painLevel }}</span
        >
      </p>
      <div fxLayout="column" fxLayoutAlign="start center">
        <app-pain-slider
          [(value)]="painLevel"
          class="pain-slider-width"
          angulartics2On="click"
          angularticsCategory="Drawing"
          angularticsAction="CurrentPain"
          angularticsLabel="slider"
          [angularticsValue]="painLevel"
        >
        </app-pain-slider>
      </div>
      <mat-divider></mat-divider>
      <p>
        <span
          style="margin: auto"
          [innerHTML]="'TOOLBOX.rate_usual_pain' | translate"
        ></span>
        <span fxFlex fxLayoutAlign="end end" class="pain-level-value">{{
          usualPainLevel
        }}</span>
      </p>
      <div fxLayout="column" fxLayoutAlign="start center">
        <app-pain-slider
          [(value)]="usualPainLevel"
          class="pain-slider-width"
          angulartics2On="click"
          angularticsCategory="Drawing"
          angularticsAction="UsualPain"
          angularticsLabel="slider"
          [angularticsValue]="usualPainLevel"
        >
        </app-pain-slider>
      </div>
      <td-message
        *ngIf="infoMessage"
        [label]="'MESSAGES.remember' | translate"
        [sublabel]="infoMessage | translate"
        color="light-blue"
        icon="info"
      ></td-message>
      <mat-divider></mat-divider>
      <mat-form-field fxFlexFill class="pad-top">
        <input
          matInput
          #noteElement
          type="text"
          name="note"
          [maxlength]="maxNoteLength"
          [(ngModel)]="note"
          [placeholder]="'GLOBAL.note_optional' | translate"
          angulartics2On="click"
          angularticsCategory="Drawing"
          angularticsAction="Note"
          angularticsLabel="input"
        />
        <mat-hint align="end"
          >{{ noteElement.value.length }} / {{ maxNoteLength }}</mat-hint
        >
      </mat-form-field>
    </td-dialog-content>
    <mat-divider class="divider-margin"></mat-divider>
    <td-dialog-actions>
      <button
        mat-button
        (click)="closeAllDialogs()"
        angulartics2On="click"
        angularticsCategory="Drawing"
        angularticsAction="CloseSaveDialog"
        angularticsLabel="dialog"
      >
        {{ 'GLOBAL.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="continue()"
        [disabled]="saveButtonDisabled"
        angulartics2On="click"
        angularticsCategory="Drawing"
        angularticsAction="Save"
        angularticsLabel="dialog"
      >
        {{
          (isLastQuestionaire ? 'GLOBAL.save' : 'GLOBAL.continue') | translate
        }}
      </button>
    </td-dialog-actions>
  </td-dialog>
</ng-template>
<ng-template #templateDialog>
  <td-dialog>
    <td-dialog-title>
      {{
        (warnFirstUse
          ? 'DIALOGS.more_body_charts.title'
          : 'TOOLBOX.select_body_chart'
        ) | translate
      }}
    </td-dialog-title>
    <td-dialog-content style="text-align: center">
      <ng-template tdLoading [tdLoadingUntil]="templateDrawings">
        <div
          *ngIf="!afterFirstView"
          style="margin-bottom: 10px"
          fxLayoutAlign="center center"
        >
          <button
            mat-raised-button
            color="primary"
            tabindex="-1"
            (click)="selectNoPain()"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="SelectNoPain"
            angularticsLabel="dialog"
          >
            {{ 'DIALOGS.more_body_charts.no_pain' | translate }}
          </button>
        </div>
        <p>{{ 'DIALOGS.more_body_charts.message' | translate }}</p>
        <div>
          <button
            mat-raised-button
            *ngFor="let drawing of templateDrawings"
            (click)="showTemplate(drawing.Template)"
            angulartics2On="click"
            angularticsCategory="Drawing"
            angularticsAction="SelectTemplate"
            angularticsLabel="dialog"
            [angularticsValue]="drawing.Template.FilePath"
          >
            <p class="unselectable" style="margin: 0">
              {{ 'CANVAS.' + drawing.Template.Name | translate }}
            </p>
            <app-chart
              [height]="drawingHeight / 5"
              [chart]="drawing.Chart"
            ></app-chart>
          </button>
        </div>
      </ng-template>
    </td-dialog-content>
    <td-dialog-actions *ngIf="warnFirstUse">
      <button
        mat-button
        (click)="closeAllDialogs()"
        angulartics2On="click"
        angularticsCategory="Drawing"
        angularticsAction="CloseTemplateDialog"
        angularticsLabel="dialog"
      >
        {{ 'GLOBAL.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="closeAllDialogs(); templateChanged = true; showSaveDialog()"
        angulartics2On="click"
        angularticsCategory="Drawing"
        angularticsAction="OpenSaveDialog"
        angularticsLabel="dialog"
      >
        {{ 'GLOBAL.save' | translate }}
      </button>
    </td-dialog-actions>
  </td-dialog>
</ng-template>
<ng-template #painTypeDialog>
  <td-dialog>
    <td-dialog-title>
      <span *ngIf="!painTypeSelected">
        {{ 'TOOLBOX.select_pain_type' | translate }}
      </span>
      <div *ngIf="painTypeSelected" fxLayoutAlign="start center">
        <button
          mat-icon-button
          (click)="painTypeSelected = false"
          angulartics2On="click"
          angularticsCategory="Drawing"
          angularticsAction="GoBackToSelectColor"
          angularticsLabel="dialog"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <span>
          {{
            'TOOLBOX.select_pain_level'
              | translate
                : { type: 'PAINTYPES.' + painType.Name | translate | lowercase }
          }}
        </span>
      </div>
    </td-dialog-title>
    <td-dialog-content
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      style="overflow-y: auto; max-width: 400px"
      [style.max-height]="documentHeight * 0.8 + 'px'"
      [style.width]="documentWidth * 0.8 + 'px'"
    >
      <ng-template
        *ngIf="!painTypeSelected"
        let-pt
        let-first="first"
        let-last="last"
        let-i="index"
        ngFor
        [ngForOf]="painTypes"
      >
        <button
          class="color-btn"
          [ngClass]="{
            'black-text': isColorLight(
              getDefaultIntensity(pt.PainIntensities).Color
            ),
            'push-top-sm': !first
          }"
          [style.background-color]="
            getDefaultIntensity(pt.PainIntensities).Color
          "
          (click)="setPainType(i)"
          angulartics2On="click"
          angularticsCategory="Drawing"
          angularticsAction="setPainType"
          [angularticsValue]="pt.Name"
          angularticsLabel="dialog"
        >
          <span class="unselectable">{{
            'PAINTYPES.' + pt.Name | translate
          }}</span>
        </button>
      </ng-template>
      <ng-template
        *ngIf="painTypeSelected"
        let-intensity
        let-first="first"
        let-i="index"
        ngFor
        [ngForOf]="painType.PainIntensities"
      >
        <button
          class="color-btn"
          [ngClass]="{
            'black-text': isColorLight(intensity.Color),
            'push-top-sm': !first
          }"
          [style.background-color]="intensity.Color"
          (click)="setPainIntensityIndex(i)"
          angulartics2On="click"
          angularticsCategory="Drawing"
          angularticsAction="SelectIntensity"
          [angularticsValue]="painType.Name + '|' + intensity.Severity"
        >
          <span class="unselectable"
            >{{ 'PAIN_SEVERITY.' + intensity.Severity | translate }} ({{
              intensity.RangeStart
            }}
            - {{ intensity.RangeEnd }})</span
          >
        </button>
      </ng-template>
    </td-dialog-content>
  </td-dialog>
</ng-template>
<mat-menu #penSizeMenu="matMenu">
  <ng-template let-s let-last="last" ngFor [ngForOf]="penSizes">
    <button
      mat-menu-item
      (click)="penSize = s; isDrawing = true"
      angulartics2On="click"
      angularticsCategory="Drawing"
      angularticsAction="PenSize"
      angularticsLabel="menu"
      [angularticsValue]="s"
    >
      <div
        fxFlexAlign="center"
        class="circle"
        [style.width]="s + 5 + 'px'"
        [style.height]="s + 5 + 'px'"
        [ngStyle]="{ 'background-color': painIntensity.Color }"
      ></div>
    </button>
    <mat-divider *ngIf="!last"></mat-divider>
  </ng-template>
</mat-menu>
<mat-menu #overlayMenu="matMenu">
  <ng-template let-o ngFor [ngForOf]="overlays">
    <button
      mat-menu-item
      (click)="toggleOverlay(o)"
      angulartics2On="click"
      angularticsCategory="Drawing"
      angularticsAction="SelectOverlay"
      angularticsLabel="menu"
      [angularticsValue]="o"
    >
      {{ 'OVERLAYS.' + o | translate }}
    </button>
  </ng-template>
</mat-menu>
