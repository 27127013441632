import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-on-container',
  templateUrl: './add-on-container.component.html',
  styleUrls: ['./add-on-container.component.scss'],
})
export class AddOnContainerComponent {
  analyticAddon = false;
  remoteAddon = false;
  @Input() set addOns(val: string[]) {
    if (val.includes('analytics')) {
      this.analyticAddon = true;
    }
    if (val.includes('remote')) {
      this.remoteAddon = true;
    }
  }
  @Output() changed = new EventEmitter<string[]>();

  get bothAddons(): boolean {
    return this.analyticAddon && this.remoteAddon;
  }

  changeAddon(addon: string) {
    if (addon === 'both') {
      const tmp = this.bothAddons;
      this.remoteAddon = !tmp;
      this.analyticAddon = !tmp;
    } else if (addon === 'remote') {
      this.remoteAddon = !this.remoteAddon;
    } else if (addon === 'analytics') {
      this.analyticAddon = !this.analyticAddon;
    }

    this.changed.emit(this.addOns);
  }

  get addOns(): string[] {
    const tmp: string[] = [];
    if (this.analyticAddon) {
      tmp.push('analytics');
    }
    if (this.remoteAddon) {
      tmp.push('remote');
    }

    return tmp;
  }
}
