import { environment } from '@environment';
import { Chart } from '@models';
import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { fabric } from 'fabric';

@Component({
  selector: 'app-chart',
  template: '<canvas #canvas [ngStyle]="{ opacity: opacity }"></canvas>',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  scrolling = false;
  prevPos!: number;
  canvas!: fabric.Canvas;
  activeOverlays: string[] = [];
  initializedOverlays: string[] = [];
  templateFilePath!: string;
  prevZoomScale = 1;
  public enableZoom = false;

  @Input() moveable = false;
  @Input() opacityOnDisabled = false;

  rescaleCanvas(
    width: number,
    height: number,
    scale: number,
    canvas: fabric.Canvas = this.canvas
  ) {
    if (canvas) {
      /** Change dimension of canvas */
      canvas.setDimensions({
        height: height,
        width: width,
      });

      canvas.setZoom(scale);

      /** Render all objects in canvas */
      canvas.renderAll();
      canvas.renderAll.bind(canvas);
    }
  }

  _height!: number;
  @Input()
  set height(height: number) {
    this._height = height;
    if (this.canvas && this.chart && height) {
      const scale = height / this.chart.Height;
      const width = scale * this.chart.Width;

      if (!(this.width && width > this.width)) {
        this.rescaleCanvas(width, height, scale);
      }
    }
  }
  get height(): number {
    return this._height;
  }

  _width!: number;
  @Input()
  set width(width: number) {
    this._width = width;
    if (this.canvas && this.chart && width) {
      const scale = width / this.chart.Width;
      const height = scale * this.chart.Height;

      if (!(this.height && height > this.height)) {
        this.rescaleCanvas(width, height, scale);
      }
    }
  }

  get width(): number {
    return this._width;
  }

  _chart!: Chart;
  @Input()
  set chart(chart: Chart) {
    this._chart = chart;
    if (chart) {
      const canvasObj =
        typeof chart.Canvas === 'string'
          ? JSON.parse(chart.Canvas)
          : chart.Canvas;
      const url = <string>canvasObj.backgroundImage.src;

      // hack to correnct height
      this._chart.Height =
        canvasObj.backgroundImage.scaleX * canvasObj.backgroundImage.height;
      this._chart.Width =
        canvasObj.backgroundImage.scaleY * canvasObj.backgroundImage.width;

      if (url.includes('/')) {
        this.templateFilePath = url.split('/')[url.split('/').length - 1];
        canvasObj.backgroundImage.src =
          environment.templatePath + this.templateFilePath;
      }

      this.canvas = this.initCanvas(canvasObj);
    }
  }

  get chart(): Chart {
    return this._chart;
  }

  initCanvas(canvasObj: any): fabric.Canvas {
    if (canvasObj && this.chart) {
      const canvas = new fabric.Canvas(this.canvasRef.nativeElement);
      canvas.selection = false;
      canvas.allowTouchScrolling = true;

      let scale = 1;
      let height = this.chart.Height;
      let width = this.chart.Width;

      if (this.height || this.width) {
        if (this.height) {
          height = this.height;
          scale = height / this.chart.Height;
          width = scale * this.chart.Width;
        } else if (this.width) {
          width = this.width;
          scale = width / this.chart.Width;
          height = scale * this.chart.Height;
        }

        if (this.width < width) {
          width = this.width;
          scale = width / this.chart.Width;
          height = scale * this.chart.Height;
        }
      }
      return canvas.loadFromJSON(canvasObj, () => {
        for (const obj of canvas.getObjects()) {
          // fix for hiding text in chart
          if (obj.type === 'text') {
            obj.set({ opacity: 0 });
          }
          obj.set({
            selectable: false,
            hasControls: false,
            hoverCursor: 'auto',
          });
        }

        canvas.on('mouse:wheel', (event: any) => {
          if (this.enableZoom) {
            const point = new fabric.Point(event.e.layerX, event.e.layerY);
            const zoomIn = event.e.deltaY < 0;
            const delta = zoomIn
              ? this.canvas.getZoom() * 1.1
              : this.canvas.getZoom() / 1.1;
            this.zoom(point, delta);
            this.renderCanvas();
          }
        });

        /** Change dimension of canvas */
        this.rescaleCanvas(width, height, scale, canvas);
        return canvas;
      });
    } else {
      throw new Error('Chart object not found');
    }
  }

  toggleZoom() {
    this.enableZoom = !this.enableZoom;

    if (!this.enableZoom) {
      this.resetZoom();
    }
  }

  toogleColor(color: string, show: boolean) {
    this.canvas.getObjects('path').forEach((e) => {
      if (
        (e.stroke && e.stroke.toUpperCase() === color.toUpperCase()) ||
        color === ''
      ) {
        e.set({
          visible: show,
        });
      }
    });
    this.renderCanvas();
  }

  resetZoom(): void {
    this.canvas.absolutePan(new fabric.Point(0, 0));
    this.canvas.setZoom(1);
    this.renderCanvas();
  }

  download(filename: string) {
    const link = document.createElement('a');
    link.download = filename + '.png';
    link.href = this.canvas
      .toDataURL()
      .replace(/^data:image\/[^;]/, 'data:application/octet-stream');
    document.body.appendChild(link);
    link.click();
  }

  toggleOverlay(overlayType: string, callback: (error?: string) => void) {
    overlayType = overlayType.replace(' ', '_').toLowerCase();
    if (this.initializedOverlays.includes(overlayType)) {
      if (this.activeOverlays.includes(overlayType)) {
        this.canvas.getObjects().forEach((o: any) => {
          if (o.overlayType === overlayType) {
            o.opacity = 0;
          }
        });
        const index = this.activeOverlays.indexOf(overlayType);
        if (index > -1) {
          this.activeOverlays.splice(index, 1);
        }
      } else {
        this.canvas.getObjects().forEach((o: any) => {
          if (o.overlayType === overlayType) {
            o.opacity = 1;
            o.bringToFront();
          }
        });
        this.activeOverlays.push(overlayType);
      }

      this.renderCanvas();
      callback();
    } else {
      const url =
        environment.overlayPath +
        overlayType +
        '/' +
        this.templateFilePath.toLowerCase().replace('png', 'svg');
      fabric.loadSVGFromURL(url, (objects, options) => {
        if (objects) {
          objects.map(
            (o) => (o.stroke = this.getColorFromOverlayType(overlayType))
          );
          const svgObj = <any>fabric.util.groupSVGElements(objects, options);
          svgObj.scaleToHeight(this.chart.Height);
          svgObj.overlayType = overlayType;
          svgObj.selectable = false;
          this.canvas.add(svgObj);
          this.renderCanvas();
          this.initializedOverlays.push(overlayType);
          if (!this.activeOverlays.includes(overlayType)) {
            this.activeOverlays.push(overlayType);
          }
          callback();
        } else {
          callback(url);
        }
      });
    }
  }

  zoom(point: any, delta: number): void {
    this.prevZoomScale = delta;
    this.canvas.zoomToPoint(point, this.prevZoomScale);
    this.renderCanvas();
  }

  renderCanvas() {
    this.canvas.renderAll();
    this.canvas.renderAll.bind(this.canvas);
  }

  get opacity(): number {
    return this.opacityOnDisabled && this.chart.Disabled ? 0.5 : 1.0;
  }

  getColorFromOverlayType(type: string): string {
    switch (type.toLowerCase()) {
      case 'dermatomes':
        return '#404040';
      case 'myotomes':
        return '#cca300';
      default:
        return '#000';
    }
  }
}
