<ng-template #inviteDialog>
  <ng-template tdLoading="submit.invite">
    <td-dialog>
      <td-dialog-title>
        {{ 'INVITE.send_invitation' | translate }}
      </td-dialog-title>
      <td-dialog-content fxLayout="column">
        <p>{{ 'INVITE.invite_to_try' | translate }}</p>
        <app-language-selector
          matLine
          [(language)]="lang"
          [placeholder]="'MENU.language' | translate"
        >
        </app-language-selector>
        <mat-form-field class="full-width">
          <input
            matInput
            #emailElement
            [placeholder]="'GLOBAL.email' | translate"
            type="text"
            [formControl]="emailFormControl"
          />
          <mat-error *ngIf="emailFormControl.hasError('pattern')">
            {{ 'FORM.valid_email' | translate }}
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            {{ 'FORM.email_required' | translate }}
          </mat-error>
        </mat-form-field>
      </td-dialog-content>
      <td-dialog-actions>
        <button mat-button #closeBtn (click)="_dialogService.closeAll()">
          {{ 'GLOBAL.cancel' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="emailFormControl.errors"
          (click)="submit(emailElement.value)"
        >
          {{ 'GLOBAL.submit' | translate }}
        </button>
      </td-dialog-actions>
    </td-dialog>
  </ng-template>
</ng-template>
