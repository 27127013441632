import { throwError, Observable } from 'rxjs';
import { Chart, Patient, ChartGroup, IdUrl } from '@models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment';
import { Injectable, Renderer2 } from '@angular/core';
import * as moment from 'moment-timezone';
import { AppTranslateService } from './app-translate.service';
import { tap } from 'rxjs/operators';
import { fabric } from 'fabric';

export interface ColorCount {
  color: string;
  count: number;
}

@Injectable()
export class ChartService {
  url = `${environment.api_path}api/v${environment.api_version}/Charts`;

  constructor(
    private _translate: AppTranslateService,
    private renderer: Renderer2,
    private http: HttpClient
  ) {}

  verifyAuthToken(token: string): Observable<Patient> {
    return this.http
      .post<Patient>(`${this.url}/AuthToken/${token}/Verify`, {})
      .pipe(tap((x) => this._translate.use(x.LanguageLocale)));
  }

  getAreaCalculations(chartId: string): Observable<Chart> {
    return this.http.get<Chart>(this.url + `/Calc/${chartId}`);
  }

  toggleChart(chartId: string, status: boolean): Observable<Chart> {
    return this.http.put<Chart>(this.url + `/Organization/${chartId}`, status);
  }

  generatePNGDataString(
    chart: Chart,
    callback: (chart: Chart) => void,
    multi = false,
    width = 250
  ): void {
    const canvasObj =
      typeof chart.Canvas === 'string'
        ? JSON.parse(chart.Canvas)
        : chart.Canvas;
    const url = <string>canvasObj.backgroundImage.src;
    if (url.includes('/')) {
      canvasObj.backgroundImage.src =
        environment.templatePath + url.split('/')[url.split('/').length - 1];
    }

    const canvasElem = this.renderer.createElement('canvas');
    const canvas = new fabric.Canvas(canvasElem);

    canvas.loadFromJSON(canvasObj, () => {
      const scale = width / chart.Width;
      const height = chart.Height * scale;

      canvas.setHeight(height);
      canvas.setWidth(width);

      canvas.setZoom(scale);

      canvas.renderAll();
      canvas.renderAll.bind(canvas);
      chart.Base64DataUrl = canvas.toDataURL();

      if (multi) {
        chart.Base64DataUrls = [];

        const colors = canvas
          .getObjects('path')
          .map((o) => o.stroke)
          .filter(this.onlyUnique);

        if (colors.length > 0) {
          colors.forEach((color) => {
            canvas.getObjects('path').forEach((e) => {
              e.set({
                visible: e.stroke === color,
              });
            });

            canvas.renderAll();
            canvas.renderAll.bind(canvas);
            chart.Base64DataUrls.push(<IdUrl>{
              id: color,
              url: canvas.toDataURL(),
            });
          });
        }
      }
      callback(chart);
    });
  }

  onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }

  totalCount(chart: Chart): number {
    if (chart.TotalCount) {
      const totalCount = JSON.parse(chart.TotalCount);
      return totalCount.Total;
    }
    return 0;
  }

  totalColorCount(chart: Chart): ColorCount[] {
    if (chart.TotalCount) {
      const totalCount = JSON.parse(chart.TotalCount);
      const colors: { color: string; count: number }[] = [];
      for (const key in totalCount) {
        if (key.match(/^#[0-9a-fA-F]{6}$/)) {
          colors.push({ color: key, count: totalCount[key] });
        }
      }

      return colors;
    }
    return [];
  }

  generateChartGroups(charts: Chart[], timezone: string): ChartGroup[] {
    const groups: ChartGroup[] = [];
    if (charts && charts.length > 0) {
      charts
        .filter((c) => !c.Disabled)
        .forEach((c) => {
          const result = groups.find((g) => g.id === c.ChartGroup);
          if (result) {
            result.charts.push(c);
          } else {
            groups.push({
              id: c.ChartGroup,
              date: moment(c.DateCreated).tz(timezone),
              charts: [c],
            });
          }
        });
      groups.sort((a, b) =>
        moment(a.date).isBefore(moment(b.date))
          ? -1
          : moment(a.date).isSameOrBefore(moment(b.date))
          ? 0
          : 1
      );
    }

    return groups;
  }
}
