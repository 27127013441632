import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timeBetween',
})
export class TimeBetweenPipe implements PipeTransform {
  transform(
    value: Date | moment.Moment,
    format: any = 'days',
    reference?: Date | moment.Moment
  ): number {
    const ref = reference ? moment(reference) : moment();
    const date = moment(value);
    return Math.max(1, Math.abs(ref.diff(date, format)));
  }
}
