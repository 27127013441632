import { environment } from '@environment';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class AppTranslateService {
  constructor(private _translate: TranslateService) {}

  setLangOnInit() {
    const langs = environment.app_langs;
    this._translate.addLangs(langs);
    const lang = localStorage.getItem('lang');
    if (lang) {
      this._translate.setDefaultLang(lang);
    } else {
      this.setDefaultLangauge(this._translate.getBrowserLang());
    }
  }

  addLangs(lang: string[]) {
    this._translate.addLangs(lang);
  }

  use(lang: string) {
    if (lang && this.languageExists(lang)) {
      localStorage.setItem('lang', lang);
    } else {
      lang = this._translate.getBrowserLang();
    }
    this._translate.use(lang);
  }

  setLanguageIfUnset(lang: string) {
    const savedLang = localStorage.getItem('lang');

    if (!savedLang) {
      this.setDefaultLangauge(lang);
    }
  }

  setDefaultLangauge(lang: string) {
    this._translate.setDefaultLang(this.languageExists(lang) ? lang : 'en');
  }

  getLangs(): string[] {
    return this._translate.getLangs();
  }

  getLang(): string {
    return this._translate.getDefaultLang();
  }

  languageExists(lang: string): boolean {
    return this.getLangs().includes(lang.toLowerCase());
  }

  get(input: string): any {
    return this._translate.get(input);
  }
}
