<mat-select
  [disabled]="disabled"
  [placeholder]="placeholder"
  (change)="changeState($event.value)"
  [(ngModel)]="state"
  name="state"
  class="full-width"
>
  <div class="search_container">
    <input
      #searchinput
      class="searchInput"
      [(ngModel)]="searchTerm"
      type="search"
      [placeholder]="searchPlaceholder"
    />
    <div class="search_results">
      <ng-template let-state let-last="last" ngFor [ngForOf]="states">
        <mat-option [value]="state">
          {{ state }}
        </mat-option>
      </ng-template>
    </div>
  </div>
</mat-select>
