import { AppTranslateService } from '@services';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewChecked,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-language-selector',
  template: `
    <mat-form-field class="full-width">
      <mat-select
        [formControl]="langCtrl"
        [placeholder]="placeholder"
        name="langauge"
        class="full-width"
      >
        <mat-option *ngFor="let lang of langs" [value]="lang">
          {{ 'LANGUAGES.' + lang | translate }}
          <span *ngIf="lang === 'ar'">(BETA)</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
})
export class LanguageSelectorComponent implements OnInit, AfterViewChecked {
  langCtrl = new FormControl();
  baseLangs: string[];
  @Input() set disabled(value: boolean) {
    if (value) {
      this.langCtrl.disable();
    } else {
      this.langCtrl.enable();
    }
  }
  @Input() extraLangs: string[] = [];
  @Input() changeAppLangOnChange = false;
  @Input() placeholder = 'Select langauge';
  @Input() set language(language: string) {
    this.langCtrl.setValue(language);
  }
  @Output() languageChange = new EventEmitter<string>();

  constructor(private _translateServive: AppTranslateService) {
    this.baseLangs = this._translateServive.getLangs();
  }

  get langs(): string[] {
    return this.baseLangs.concat(this.extraLangs);
  }

  ngOnInit() {
    this.langCtrl.valueChanges.subscribe((val) => this.changeLang(val));
  }

  ngAfterViewChecked() {
    if (!this.language) {
      const language = this._translateServive.getLang();

      // quick fix for ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
        this.changeLang(language);
        this.langCtrl.setValue(language);
      });
    }
  }

  get language(): string {
    return this.langCtrl.value;
  }

  changeLang(lang: string) {
    this.languageChange.emit(lang);
    if (this.changeAppLangOnChange) {
      this._translateServive.use(lang);
    }
  }
}
