import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pain-level-bar',
  templateUrl: './pain-level-bar.component.html',
  styleUrls: ['./pain-level-bar.component.scss'],
})
export class PainLevelBarComponent implements OnInit {
  @Input() vertical = false;
  @Input() currentPainLevel!: number;
  @Input() usualPainLevel!: number;
  @Input() currentPainPlaceholder!: string;
  @Input() usualPainPlaceholder!: string;
  constructor() {}

  ngOnInit() {}
}
