import { interval } from 'rxjs';
import { AppTranslateService } from '@services';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from '@environment';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { SwUpdate } from '@angular/service-worker';
import { WindowRef } from '@shared/windowRef';

declare const gtag: any;

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> <app-tour-step></app-tour-step> `,
})
export class AppComponent implements OnInit {
  constructor(
    private _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    // private swUpdate: SwUpdate,
    private winRef: WindowRef,
    private snackBar: MatSnackBar,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private appTranslate: AppTranslateService
  ) {
    gtag('config', environment.google_analytics_key, { anonymize_ip: true });
    this.angulartics2GoogleTagManager.startTracking();
    this.appTranslate.setLangOnInit();

    this._iconRegistry.addSvgIconInNamespace(
      'icon',
      'brush-plus',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/brush-plus.svg'
      )
    );
    /*
    this._iconRegistry.addSvgIconInNamespace('icon', 'visa',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/visa.svg')
    );
    this._iconRegistry.addSvgIconInNamespace('icon', 'mastercard',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/mastercard.svg')
    );
    */
  }

  ngOnInit() {
    /*
    this.swUpdate.available.subscribe((event) => {
      console.log(
        '[APP] Update available: current version is',
        event.current,
        'available version is',
        event.available
      );
      // TODO: translate
      const snackBarRef = this.snackBar.open(
        'New version is available',
        'Update'
      );

      snackBarRef.onAction().subscribe(() => {
        this.winRef.nativeWindow.location.reload();
      });
    });

    const intervalTime = environment.production
      ? 60 * 60 * 1000 // 1 hour
      : 5 * 60 * 1000; // 5 min

    if (this.swUpdate.isEnabled) {
      interval(intervalTime).subscribe((_) =>
        this.swUpdate
          .checkForUpdate()
          .then((_) => console.log('[APP] Checking for updates'))
      );
    }
    */
  }
}
