import { Component } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-footer-link',
  templateUrl: './footer-link.component.html',
  styleUrls: ['./footer-link.component.scss'],
})
export class FooterLinkComponent {
  version = environment.version;
  year = new Date().getFullYear();
}
