import { of as observableOf, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '@models';
import { environment } from '@environment';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AccountService {
  url = `${environment.api_path}api/v${environment.api_version}/Account`;
  constructor(private auth: AuthService, private http: HttpClient) {}

  changePassword(body: any): Observable<any> {
    return this.http.post(this.url + '/ChangePassword', body);
  }

  resetPassword(email: string, locale: string): Observable<any> {
    const params = new HttpParams().set('email', email).set('locale', locale);

    return this.http.post(this.url + '/ResetPassword', {}, { params: params });
  }

  setPassword(body: any): Observable<any> {
    return this.http.post(this.url + '/SetPassword', body);
  }

  changeEmail(newEmail: string, password: string): Observable<any> {
    return this.http.post(this.url + '/ChangeEmail', {
      Email: newEmail,
      Password: password,
    });
  }

  confirmChangeEmail(body: any) {
    return this.http.post(this.url + '/ConfirmChangeEmail', body);
  }

  confirmEmail(body: any) {
    return this.http.post(this.url + '/ConfirmEmail', body);
  }

  acceptTerms() {
    return this.http.post(this.url + '/AcceptTerms', {});
  }

  denyTerms() {
    return this.http.post(this.url + '/DenyTerms', {});
  }

  submitFeedback(body: {
    Feedback: string;
    Satisfactory: number;
    LanguageLocale: string;
  }): Observable<any> {
    return this.http.post(
      `${environment.api_path}api/v${environment.api_version}/UserFeedback`,
      body
    );
  }

  submitInvite(body: { email: string; languageLocale: string }) {
    return this.http.post(
      `${environment.api_path}api/v${environment.api_version}/UserFeedback/Invite`,
      body
    );
  }
}
