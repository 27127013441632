export class User {
  access_token?: string;
  token_type?: string;
  refresh_token?: string;
  Email?: string;
  Id?: string;
  Roles?: string;
  Fullname?: string;
  LanguageLocale?: string;
  Timezone?: string;
  rolesArray: string[];

  /*
  private get rolesArray(): string[] {
    if (!this.Roles) {
      return [];
    } else {
      return this.Roles.split(',');
    }
  }
  */

  public get isPatient(): boolean {
    return this.rolesArray.includes('Patient');
  }

  public get isResearchPersonnel(): boolean {
    return this.rolesArray.includes('ResearchPersonnel');
  }

  public get isResearchManager(): boolean {
    return this.rolesArray.includes('ResearchManager');
  }

  public get isAdmin(): boolean {
    return this.rolesArray.includes('Admin');
  }

  constructor(user: User | undefined) {
    if (!user) {
      user = <User>{};
    }
    this.access_token = user.access_token;
    this.token_type = user.token_type;
    this.refresh_token = user.refresh_token;
    this.Email = user.Email;
    this.Id = user.Id;
    this.Roles = user.Roles;
    this.Fullname = user.Fullname;
    this.LanguageLocale = user.LanguageLocale;
    this.Timezone = user.Timezone;
  }
}
