import {
  Directive,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[copy]',
})
export class CopyDirective {
  @Input() copy!: string;
  @Output() copied = new EventEmitter();
  @HostListener('click')
  public onClick(): void {
    this.copyToClipBoard(this.copy);
  }

  copyToClipBoard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied.emit();
  }
}
