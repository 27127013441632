<ng-template #exportDialog>
  <td-dialog>
    <td-dialog-title>
      {{ 'GLOBAL.export_patient' | translate }}
    </td-dialog-title>
    <td-dialog-content class="mat-subhead tc-grey-700" fxLayout="column">
      <ng-template tdLoading [tdLoadingUntil]="initialized">
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-label>{{ 'GLOBAL.periode' | translate }}</mat-label>
            <mat-date-range-input
              [formGroup]="dateRangeGroup"
              [min]="minDate"
              [max]="maxDate"
              [rangePicker]="picker"
            >
              <input
                matStartDate
                formControlName="start"
                (click)="picker.open()"
                [placeholder]="'GLOBAL.start_date' | translate"
              />
              <input
                matEndDate
                formControlName="end"
                (click)="picker.open()"
                [placeholder]="'GLOBAL.end_date' | translate"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <td-message
          #infoMsg
          color="light-blue"
          [opened]="false"
          icon="info"
          [label]="'GLOBAL.periode' | translate"
          [sublabel]="
            ('GLOBAL.select' | translate) + ' ' + ('GLOBAL.periode' | translate)
          "
        ></td-message>
        <mat-radio-group
          class="pad-sm"
          fxFlex
          fxLayout="row"
          fxLayoutAlign="space-evenly center"
          [(ngModel)]="settings.type"
        >
          <mat-radio-button
            *ngFor="let type of exportTypes"
            [disabled]="type === 'redCap'"
            [value]="type"
            >{{ type | uppercase }}</mat-radio-button
          >
        </mat-radio-group>
        <div
          *ngIf="settings.type !== 'png'"
          fxLayout="row"
          fxLayoutAlign="space-between"
        >
          <div fxLayout="column" fxLayoutGap="15px">
            <mat-checkbox color="primary" [(ngModel)]="settings.name"
              >{{ 'GLOBAL.show' | translate }}
              {{ 'GLOBAL.name' | translate }}</mat-checkbox
            >
            <mat-checkbox color="primary" [(ngModel)]="settings.email"
              >{{ 'GLOBAL.show' | translate }}
              {{ 'GLOBAL.email' | translate }}</mat-checkbox
            >
            <mat-checkbox color="primary" [(ngModel)]="settings.age"
              >{{ 'GLOBAL.show' | translate }}
              {{ 'GLOBAL.age' | translate }}</mat-checkbox
            >
            <mat-checkbox color="primary" [(ngModel)]="settings.note"
              >{{ 'GLOBAL.show' | translate }}
              {{ 'GLOBAL.note' | translate }}</mat-checkbox
            >
          </div>
          <div fxLayout="column" fxLayoutGap="15px">
            <mat-checkbox color="primary" [(ngModel)]="settings.np_code"
              >{{ 'GLOBAL.show' | translate }} NP-code</mat-checkbox
            >
            <mat-checkbox color="primary" [(ngModel)]="settings.birthDate"
              >{{ 'GLOBAL.show' | translate }}
              {{ 'GLOBAL.date_of_birth' | translate }}</mat-checkbox
            >
            <mat-checkbox color="primary" [(ngModel)]="settings.gender"
              >{{ 'GLOBAL.show' | translate }}
              {{ 'GLOBAL.gender' | translate }}</mat-checkbox
            >
          </div>
        </div>
        <div class="slide-toggle" fxLayout="row" fxLayout.xs="column">
          <mat-form-field matLine class="full-width">
            <mat-select
              matLine
              [placeholder]="'GLOBAL.date_format' | translate"
              [(ngModel)]="settings.dateFormat"
            >
              <mat-option *ngFor="let format of dateFormats" [value]="format">
                {{ format }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field matLine class="full-width">
            <mat-select
              matLine
              [placeholder]="'GLOBAL.time_format' | translate"
              [(ngModel)]="settings.timeFormat"
            >
              <mat-option *ngFor="let format of timeFormats" [value]="format">
                {{ format }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <td-message
          color="red"
          [opened]="isOldSafari"
          icon="warning"
          [label]="'MESSAGES.outdated_safari_version' | translate"
          [sublabel]="'MESSAGES.outdated_safari_version_text' | translate"
        ></td-message>
      </ng-template>
    </td-dialog-content>

    <td-dialog-actions>
      <button mat-button #closeBtn (click)="_dialogService.closeAll()">
        {{ 'GLOBAL.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        [disabled]="!initialized"
        color="primary"
        (click)="export()"
      >
        {{ 'GLOBAL.export' | translate }}
      </button>
    </td-dialog-actions>
  </td-dialog>
</ng-template>
