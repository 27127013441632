import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.auth.hasSession) {
      const redirectUrl = route.queryParamMap.get('redirectUrl') || '/';
      this.router.navigateByUrl(redirectUrl);
      return false;
    }
    return true;
  }
}
