import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: number, args?: any): string {
    return (value > 9 ? value : '0' + value) + ':00';
  }
}
