import { Directive } from '@angular/core';
import { HostListener, Host, Optional } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appAutoTrim]',
})
export class AutoTrimDirective {
  constructor(@Optional() @Host() private model: NgModel) {}

  /**
   * Listens to host's (blur) event and trims value.
   */
  @HostListener('blur', ['$event'])
  onBlur(event: Event): void {
    if (
      this.model &&
      this.model.value &&
      typeof this.model.value === 'string'
    ) {
      this.model.update.emit(this.model.value.trim());
    }
  }
}
