import { TdLoadingService } from '@covalent/core/loading';
import {
  Component,
  ViewEncapsulation,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountService, AppTranslateService } from '@services';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [AccountService],
})
export class InviteDialogComponent {
  @ViewChild('inviteDialog') inviteDialog!: TemplateRef<any>;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  lang!: string;

  constructor(
    public _dialogService: MatDialog,
    private accountService: AccountService,
    private translateService: AppTranslateService,
    private _snackBar: MatSnackBar,
    private _loadingService: TdLoadingService
  ) {}

  open() {
    this._dialogService.open(this.inviteDialog);
  }

  submit(email: string) {
    this._loadingService.register('submit.invite');
    this.accountService
      .submitInvite({
        email: email,
        languageLocale: this.lang ? this.lang : this.translateService.getLang(),
      })
      .subscribe(
        (res) => {
          this.translateService.get('MESSAGES').subscribe((messages: any) => {
            this._snackBar.open(messages.success, undefined, {
              duration: 2500,
              panelClass: ['primary-green'],
            });
            this._loadingService.resolve('submit.invite');
            this._dialogService.closeAll();
          });
        },
        (error) => {
          this._snackBar.open(
            error.Message,
            error.Dismiss ? error.Dismiss : 'Dismiss'
          );
          this._loadingService.resolve('submit.invite');
          this._dialogService.closeAll();
        }
      );
  }
}
