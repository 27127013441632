import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ResearchOrganizationStatus {
  TermsAccepted: Date;
  IsManager: boolean;
  AccessLevel: AccessLevel;
}

export interface PatientStatus {
  Organizations: {
    Id: string;
    Name: string;
  }[];
  TermsAccepted: Date;
  Under16: boolean;
}

export interface AccessLevel {
  Expired: boolean;
  HasAccess: boolean;
  TestPatients: boolean;
  RemotePatients: boolean;
  PixelCount: boolean;
  SubCalculations: boolean;
  TimelineView: boolean;
  DermatomeOverlay: boolean;
  MyotomeOverlay: boolean;
  BodyRegionOverlay: boolean;
  ExportPDF: boolean;
  ExportPNG: boolean;
  ExportCSV: boolean;
  ExportPDFBulk: boolean;
  ExportPNGBulk: boolean;
  ExportPNGBulkPerPainColor: boolean;
  ExportCSVBulk: boolean;
  ExportSubCalculationsPDF: boolean;
  ExportSubCalculationsCSV: boolean;
  ExportRedcap: boolean;
  ExportHeatmap: boolean;
  AuthToken: boolean;
  EmailReminders: boolean;
  EmailSupport: boolean;
  PhoneSupport: boolean;
  Overlays?: string[];
}

@Injectable()
export class StatusService {
  url = `${environment.api_path}api/v${environment.api_version}/`;
  _researchOrganizationStatus!: ResearchOrganizationStatus;

  constructor(private http: HttpClient) {}

  get researchOrganizationAccessLevel(): Observable<AccessLevel> {
    return this.getResearchOrganizationStatus(true).pipe(
      map((x) => x.AccessLevel)
    );
  }

  getResearchOrganizationStatus(
    returnCached = false
  ): Observable<ResearchOrganizationStatus> {
    if (returnCached && this._researchOrganizationStatus) {
      return of(this._researchOrganizationStatus);
    }
    return this.http
      .get<ResearchOrganizationStatus>(this.url + 'Research/Status')
      .pipe(
        map((x) => {
          const overlays: string[] = [];
          if (x.AccessLevel.BodyRegionOverlay) {
            overlays.push('body_regions');
          }
          if (x.AccessLevel.DermatomeOverlay) {
            overlays.push('dermatomes');
          }
          if (x.AccessLevel.MyotomeOverlay) {
            overlays.push('myotomes');
          }
          x.AccessLevel.Overlays = overlays;
          this._researchOrganizationStatus = x;
          return x;
        })
      );
  }

  getPatientStatus(): Observable<PatientStatus> {
    return this.http.get<PatientStatus>(this.url + 'Patients/Status');
  }
}
