import { PainType } from '@models';
import { Injectable } from '@angular/core';

@Injectable()
export class PainService {
  private painTypeMap: any = JSON.parse(
    '{"#6D120F":{"Name":"Pain","Severity":"Mild"},"#8C1713":{"Name":"Pain","Severity":"Moderate"},"#AA1C17":{"Name":"Pain","Severity":"Severe"},"#A33940":{"Name":"Dull/Aching","Severity":"Mild"},"#D14952":{"Name":"Dull/Aching","Severity":"Moderate"},"#FF5964":{"Name":"Dull/Aching","Severity":"Severe"},"#873400":{"Name":"Burning","Severity":"Mild"},"#AE4310":{"Name":"Burning","Severity":"Moderate"},"#D45113":{"Name":"Burning","Severity":"Severe"},"#A35F04":{"Name":"Throbbing","Severity":"Mild"},"#D17A05":{"Name":"Throbbing","Severity":"Moderate"},"#FF9505":{"Name":"Throbbing","Severity":"Severe"},"#48A813":{"Name":"Stabbing","Severity":"Mild"},"#5FD713":{"Name":"Stabbing","Severity":"Moderate"},"#AFFC41":{"Name":"Stabbing","Severity":"Severe"},"#138771":{"Name":"Tingling","Severity":"Mild"},"#1BAD91":{"Name":"Tingling","Severity":"Moderate"},"#1DD3B0":{"Name":"Tingling","Severity":"Severe"},"#004B70":{"Name":"Electric","Severity":"Mild"},"#006090":{"Name":"Electric","Severity":"Moderate"},"#0075AF":{"Name":"Electric","Severity":"Severe"},"#3C153B":{"Name":"Numbness","Severity":"Mild"},"#5F225E":{"Name":"Numbness","Severity":"Moderate"},"#822E81":{"Name":"Numbness","Severity":"Severe"},"#234F54":{"Name":"Cold","Severity":"Mild"},"#2D666D":{"Name":"Cold","Severity":"Moderate"},"#367C84":{"Name":"Cold","Severity":"Severe"},"#700033":{"Name":"Itchy","Severity":"Mild"},"#900041":{"Name":"Itchy","Severity":"Moderate"},"#AF004F":{"Name":"Itchy","Severity":"Severe"},"#5FA3AC":{"Name":"Other","Severity":"Mild"},"#73C6D1":{"Name":"Other","Severity":"Moderate"},"#7ED9E5":{"Name":"Other","Severity":"Severe"}}'
  );

  compare(color1: string, color2: string) {
    const colorArr: string[] = Object.keys(this.painTypeMap);
    return colorArr.indexOf(color1) - colorArr.indexOf(color2);
  }

  findMissingColor(colors: string[]): string[] {
    const painTypes: string[] = [];
    const newColors: string[] = [];
    for (const color of colors) {
      const painType = this.getPainTypeFromColor(color).Name;
      if (!painTypes.includes(painType)) {
        painTypes.push(painType);
        for (const key of Object.keys(this.painTypeMap)) {
          const pt = this.painTypeMap[key];
          if (pt.Name === painType && !colors.includes(key)) {
            newColors.push(key);
          }
        }
      }
    }
    return newColors;
  }

  getPainTypeFromColor(color: string): PainType {
    if (this.painTypeMap[color]) {
      return this.painTypeMap[color];
    } else {
      throw new Error(color + ' not found in painTypeMap');
    }
  }

  getSeverityFromColor(color: string): string {
    if (this.painTypeMap[color]) {
      return this.painTypeMap[color].Severity;
    } else {
      throw new Error(color + ' not found in painTypeMap');
    }
  }

  getPainTypeNameFromColor(color: string, sep = ' '): string {
    return (
      this.getSeverityFromColor(color) +
      sep +
      this.getPainTypeFromColor(color).Name
    );
  }
}
