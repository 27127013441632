import { AuthService } from '@services';
import { TdLoadingService } from '@covalent/core/loading';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forward',
  template: '<ng-template tdLoading="load"></ng-template>',
})
export class ForwardComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _loadingService: TdLoadingService
  ) {}

  ngOnInit(): void {
    this._loadingService.register('load');
    this._route.params.subscribe((params: any) => {
      const user = this.auth.user;
      if (user && user.Id === params.id) {
        this._router.navigate(['/patient-portal']);
      } else {
        this._router.navigate(['/login']);
      }
    });
  }
}
