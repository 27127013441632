import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo1',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform {
  transform(time: any, locale: any, reference: any): string {
    // Convert time to date object if not already
    time = new Date(time);
    const ref: Date = new Date(reference);

    // If not a valid timestamp, return String('Invalid Date'
    if (!time.getTime()) {
      return String('Invalid Date');
    }

    // For unit testing, we need to be able to declare a static start time
    // for calculations, or else speed of tests can bork.
    const startTime: number = isNaN(ref.getTime()) ? Date.now() : ref.getTime();
    let diff: number = Math.floor((startTime - time.getTime()) / 1000);
    const agoText = true ? '' : ' ' + locale.ago;
    if (diff < 2) {
      return String('1 ' + locale.second + agoText);
    }
    if (diff < 60) {
      return String(Math.floor(diff) + ' ' + locale.seconds + agoText);
    }
    // Minutes
    diff = diff / 60;
    if (diff < 2) {
      return String('1 ' + locale.second + agoText);
    }
    if (diff < 60) {
      return String(Math.floor(diff) + ' ' + locale.minutes + agoText);
    }
    // Hours
    diff = diff / 60;
    if (diff < 2) {
      return String('1 ' + locale.hour + agoText);
    }
    if (diff < 24) {
      return String(Math.floor(diff) + ' ' + locale.hours + agoText);
    }
    // Days
    diff = diff / 24;
    if (diff < 2) {
      return String('1 ' + locale.day + agoText);
    }
    if (diff < 30) {
      return String(Math.floor(diff) + ' ' + locale.days + agoText);
    }
    // Months
    diff = diff / 30;
    if (diff < 2) {
      return String('1 ' + locale.month + agoText);
    }
    if (diff < 12) {
      return String(Math.floor(diff) + ' ' + locale.months + agoText);
    }
    // Years
    diff = diff / 12;
    if (diff < 2) {
      return String('1 ' + locale.year + agoText);
    } else {
      return String(Math.floor(diff) + ' ' + locale.years + agoText);
    }
  }
}
