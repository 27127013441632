<div id="slidecontainer">
  <input
    #slideelement
    class="slider {{ useSmileys ? classFromElement(slideelement) : '' }}"
    type="range"
    min="0"
    [(ngModel)]="value"
    [max]="max"
    step="0.1"
    [disabled]="disabled"
  />
  <div class="labels unselectable">
    <h4 class="mat-caption left">{{ 'TOOLBOX.no_pain' | translate }}</h4>
    <h4 class="mat-caption right">{{ 'TOOLBOX.worst_pain' | translate }}</h4>
    <h4 class="mat-caption center">
      {{ 'TOOLBOX.moderate_pain' | translate }}
    </h4>
  </div>
</div>
