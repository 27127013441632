export const environment = {
  production: false,
  version: require('../../../../package.json').version + '-dev',
  supabaseUrl: 'https://tneuledogbndrxpfjmdf.supabase.co',
  supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRuZXVsZWRvZ2JuZHJ4cGZqbWRmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYxOTQxNDUsImV4cCI6MjA1MTc3MDE0NX0.fN8wfbjpN6X7Le2CENwykvcMEIqDFo4Mt9V1rCANFfE',
  templatePath: 'assets/img/templates/',
  overlayPath: 'assets/img/overlays/',
  api_path: 'https://api.navigatepain.com/',
  // api_path: 'http://localhost:59893/',
  api_version: '1',
  sentry_key: '7be0c290b5784c18a88ff9210a6656bd',
  sentry_app: '362646',
  google_analytics_key: 'UA-102907717-4',
  app_langs: ['en', 'es', 'da', 'de', 'fr', 'it', 'pl', 'tr'],
  gender_list: ['Female', 'Male', 'Other'],
};
