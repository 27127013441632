<ng-template #newScheduleDialog>
  <td-dialog>
    <td-dialog-title>
      <mat-icon>{{ edit ? 'alarm' : 'add_alarm' }}</mat-icon>
      {{
        (disabled
          ? 'PATIENT.schedule'
          : edit
          ? 'SCHEDULE.edit_schedule'
          : 'PATIENT.new_schedule'
        ) | translate
      }}
      <span class="right_close_btn">
        <button
          mat-icon-button
          fxHide.gt-xs
          *ngIf="!disabled && edit"
          [matMenuTriggerFor]="scheduleMenu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #scheduleMenu="matMenu">
          <button
            mat-menu-item
            *ngIf="edit"
            color="warn"
            #deleteBtn
            (click)="deleteSchedule()"
          >
            {{ 'GLOBAL.delete' | translate }}
          </button>
        </mat-menu>
      </span>
    </td-dialog-title>
    <ng-template #noAccess>
      <td-dialog-content>
        {{ 'MESSAGES.schedules_upgrade_subscription' | translate }}
      </td-dialog-content>
    </ng-template>
    <td-dialog-content
      *ngIf="hasAccess; else noAccess"
      class="mat-subhead tc-grey-700"
    >
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <mat-label>{{ 'GLOBAL.periode' | translate }}</mat-label>
          <mat-date-range-input
            [formGroup]="dateRangeGroup"
            [min]="minDate"
            [disabled]="disabled"
            [rangePicker]="picker"
          >
            <input
              matStartDate
              formControlName="start"
              [placeholder]="'GLOBAL.start_date' | translate"
            />
            <input
              matEndDate
              formControlName="end"
              [placeholder]="'GLOBAL.end_date' | translate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input
            *ngIf="disabled"
            matInput
            [placeholder]="'SCHEDULE.title' | translate"
            type="text"
            [(ngModel)]="schedule.Title"
            [disabled]="disabled"
          />
          <input
            *ngIf="!disabled"
            matInput
            [placeholder]="'SCHEDULE.title' | translate"
            type="text"
            [(ngModel)]="schedule.Title"
          />
          <mat-error *ngIf="!schedule.Title">
            {{ 'FORM.required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" *ngIf="!disabled || schedule.Description">
        <mat-form-field fxFlex>
          <input
            matInput
            [placeholder]="'GLOBAL.description' | translate"
            type="text"
            [(ngModel)]="schedule.Description"
            [disabled]="disabled"
          />
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <mat-select
            #intervalSelector
            fxFlex
            required
            [placeholder]="'SCHEDULE.interval' | translate"
            [(ngModel)]="schedule.Interval"
            [disabled]="disabled"
            (change)="changeInterval($event.value)"
          >
            <mat-option *ngFor="let i of intervals" [value]="i">
              {{ 'TIMES.' + i | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="schedule.Interval" style="margin-top: 20px">
        <div *ngIf="schedule.Interval === 'daily'">
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <mat-select
                #hoursSelector
                fxFlex
                multiple
                [placeholder]="'SCHEDULE.select_timestamps' | translate"
                [(ngModel)]="schedule.Reminders"
                (change)="
                  schedule.Reminders?.length > 2 ? hoursSelector.close() : ''
                "
                [disabled]="disabled"
              >
                <mat-option
                  *ngFor="let option of hours"
                  [value]="option"
                  [disabled]="
                    schedule.Reminders?.length >= allowedHours &&
                    schedule.Reminders?.indexOf(option) === -1
                  "
                >
                  {{ option | timeFormat }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="schedule.Interval === 'weekly'">
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <mat-select
                fxFlex
                multiple
                [placeholder]="'SCHEDULE.select_weekdays' | translate"
                [(ngModel)]="schedule.Reminders"
                [disabled]="disabled"
              >
                <mat-option *ngFor="let option of weekdays" [value]="option">
                  {{ 'WEEKDAYS.' + option | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="schedule.Interval === 'monthly'">
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <mat-select
                fxFlex
                multiple
                [placeholder]="'SCHEDULE.select_days' | translate"
                [(ngModel)]="schedule.Reminders"
                [disabled]="disabled"
              >
                <mat-option *ngFor="let option of days" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <td-message
        *ngIf="errorMessage"
        [label]="'MESSAGES.error' | translate"
        [sublabel]="errorMessage"
        color="warn"
        icon="error"
      ></td-message>
    </td-dialog-content>
    <td-dialog-actions *ngIf="!disabled">
      <button mat-button #closeBtn (click)="_dialogService.closeAll()">
        {{ 'GLOBAL.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="!hasAccess"
        color="primary"
        (click)="requestAccess()"
      >
        {{ 'PAYMENT.request_access' | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="hasAccess && !edit"
        color="primary"
        #saveBtn
        (click)="saveSchedule()"
        [disabled]="
          !dateRangeGroup.valid ||
          !schedule.Interval ||
          !schedule.Title ||
          schedule.Reminders?.length < 1
        "
      >
        {{ 'GLOBAL.create' | translate }}
      </button>
      <button
        mat-raised-button
        fxHide.xs
        *ngIf="hasAccess && edit"
        color="warn"
        #deleteBtn
        (click)="deleteSchedule()"
      >
        {{ 'GLOBAL.delete' | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="hasAccess && edit"
        color="primary"
        #editBtn
        [disabled]="!dateRangeGroup.valid || !schedule.Interval || !schedule.Title"
        (click)="updateSchedule()"
      >
        {{ 'GLOBAL.edit' | translate }}
      </button>
    </td-dialog-actions>
  </td-dialog>
</ng-template>
