import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'appTimeAgo',
})
export class AppTimeAgoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(input: any, reference?: any): Observable<string> {
    // Convert time to date object if not already
    const time = moment.utc(input);
    const startTime = moment.utc(reference);

    // If dates is not a valid moment js date, return 'Invalid Date'
    if (!input || !time.isValid() || !startTime.isValid()) {
      return observableOf('Invalid Date');
    }

    // For unit testing, we need to be able to declare a static start time
    // for calculations, or else speed of tests can bork.
    let diff: number = moment.duration(startTime.diff(time)).asSeconds();

    const inPast = diff >= 0;
    const translationPrefix = inPast ? 'TIMES_AGO' : 'IN_TIME';

    // Get absolute number for correct limits
    diff = Math.abs(diff);

    if (diff < 2) {
      return this.translate
        .get(`${translationPrefix}.second`)
        .pipe(map((res) => res));
    }
    if (diff < 60) {
      return this.translate
        .get(`${translationPrefix}.seconds`, { time: Math.floor(diff) })
        .pipe(map((res) => res));
    }
    // Minutes
    diff = diff / 60;
    if (diff < 2) {
      return this.translate
        .get(`${translationPrefix}.minute`)
        .pipe(map((res) => res));
    }
    if (diff < 60) {
      return this.translate
        .get(`${translationPrefix}.minutes`, { time: Math.floor(diff) })
        .pipe(map((res) => res));
    }
    // Hours
    diff = diff / 60;
    if (diff < 2) {
      return this.translate
        .get(`${translationPrefix}.hour`)
        .pipe(map((res) => res));
    }
    if (diff < 24) {
      return this.translate
        .get(`${translationPrefix}.hours`, { time: Math.floor(diff) })
        .pipe(map((res) => res));
    }
    // Days
    diff = diff / 24;
    if (diff < 2) {
      return this.translate
        .get(`${translationPrefix}.day`)
        .pipe(map((res) => res));
    }
    if (diff < 30) {
      return this.translate
        .get(`${translationPrefix}.days`, { time: Math.floor(diff) })
        .pipe(map((res) => res));
    }
    // Months
    diff = diff / 30;
    if (diff < 2) {
      return this.translate
        .get(`${translationPrefix}.month`)
        .pipe(map((res) => res));
    }
    if (diff < 12) {
      return this.translate
        .get(`${translationPrefix}.months`, { time: Math.floor(diff) })
        .pipe(map((res) => res));
    }
    // Years
    diff = diff / 12;
    if (diff < 2) {
      return this.translate
        .get(`${translationPrefix}.year`)
        .pipe(map((res) => res));
    } else {
      return this.translate
        .get(`${translationPrefix}.years`, { time: Math.floor(diff) })
        .pipe(map((res) => res));
    }
  }
}
