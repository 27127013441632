import { DigitsPipe } from './../pipes/digits.pipe';
import { LimitArrayPipe } from './../pipes/limit-array.pipe';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { TermsTextComponent } from './terms/text/text.component';
import { CanvasVideoComponent } from './../fabric/chart-video/canvas-video/canvas-video.component';
import { ChartVideoComponent } from './../fabric/chart-video/chart-video.component';
import { DrawComponent } from './../fabric/draw/draw.component';
import { ChartComponent } from './../fabric/chart/chart.component';
import { ChartContainerComponent } from './../fabric/chart-container/chart-container.component';
import { CanvasComponent } from './../fabric/canvas/canvas.component';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { StateSelectorComponent } from './state-selector/state-selector.component';
import { FeedbackDialogComponent } from './dialogs/feedback/feedback.component';
import { ScheduleDialogComponent } from './dialogs/schedule-dialog/schedule-dialog.component';
import { LongpressDirective } from './directives/longpress.directive';
import { ExportComponent } from './export/export.component';
import { PainLevelBarComponent } from './pain-level-bar/pain-level-bar.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { PainSliderComponent } from './pain-slider/pain-slider.component';
import { InviteDialogComponent } from './dialogs/invite/invite.component';
import { WindowRef } from './windowRef';
import { NavigatorRef } from './navigatorRef';
import { MessageDevelopmentComponent } from './messages/development/development.component';
import { StartTourDialogComponent } from './dialogs/start-tour/start-tour.component';
import { PlanContainerComponent } from './payment/plan-container/plan-container.component';
import {
  ResearchPersonnelGuard,
  PatientPersonnelGuard,
  ResearchManagerGuard,
  DiscardChangesGuard,
  PatientStatusGuard,
  ResearchOrganizationStatusGuard,
} from '@guards';
import { TokenListComponent } from './token-list/token-list.component';
import { CopyDirective } from './directives/copy.directive';
import { AddOnContainerComponent } from './payment/add-on-container/add-on-container.component';
import {
  TzPipe,
  TimeAgoPipe,
  AgePipe,
  EmptyPipe,
  TimeBetweenPipe,
  TimeFormatPipe,
  AppTimeAgoPipe,
  ReversedPipe,
  AppCurrencyPipe,
} from '@pipes';
import {
  StatusService,
  PainService,
  PlanService,
  TemplateService,
  AppTranslateService,
} from '@services';
import { FooterLinkComponent } from './footer/footer-link/footer-link.component';
import { TimezonePickerComponent } from './timezone-picker/timezone-picker.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { FabComponent } from './fab/fab.component';
import { AutoTrimDirective } from './directives/auto-trim.directive';
import { CovalentMediaModule } from '@covalent/core/media';
import { CovalentLoadingModule } from '@covalent/core/loading';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { CovalentMessageModule } from '@covalent/core/message';
import { CovalentSearchModule } from '@covalent/core/search';
import { CovalentNotificationsModule } from '@covalent/core/notifications';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentMenuModule } from '@covalent/core/menu';
import { CovalentStepsModule } from '@covalent/core/steps';
import { CovalentDataTableModule } from '@covalent/core/data-table';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';

const ANGULAR_MODULES: any[] = [FormsModule, ReactiveFormsModule];

const MATERIAL_MODULES: any[] = [
  FlexLayoutModule,
  MatRadioModule,
  MatRippleModule,
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatInputModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatTabsModule,
  MatSelectModule,
  MatSliderModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
];

const COVALENT_MODULES: any[] = [
  CovalentDataTableModule,
  CovalentMediaModule,
  CovalentLoadingModule,
  CovalentNotificationsModule,
  CovalentLayoutModule,
  CovalentMenuModule,
  CovalentSearchModule,
  CovalentDialogsModule,
  CovalentMessageModule,
  CovalentStepsModule,
];

const EXTERNAL_MODULES: any[] = [
  NgxChartsModule,
  UiSwitchModule,
  NgxMatSelectSearchModule,
];

const SERVICES: any[] = [
  StatusService,
  PainService,
  PlanService,
  TemplateService,
  AppTranslateService,
];

export const GUARDS: any[] = [
  ResearchPersonnelGuard,
  PatientPersonnelGuard,
  ResearchManagerGuard,
  DiscardChangesGuard,
  PatientStatusGuard,
  ResearchOrganizationStatusGuard,
];

export const DIALOGS: any[] = [StartTourDialogComponent];

export const PIPES: any[] = [
  DigitsPipe,
  TzPipe,
  TimeAgoPipe,
  AgePipe,
  EmptyPipe,
  TimeBetweenPipe,
  TimeFormatPipe,
  AppCurrencyPipe,
  AppTimeAgoPipe,
  LimitArrayPipe,
  ReversedPipe,
];

export const DIRECTIVES: any[] = [
  LongpressDirective,
  AutoTrimDirective,
  CopyDirective,
];

export const INTERNAL_DECLARARIONS: any[] = [
  LanguageSelectorComponent,
  CountrySelectorComponent,
  StateSelectorComponent,
  ChartContainerComponent,
  CanvasComponent,
  ChartComponent,
  DrawComponent,
  FeedbackDialogComponent,
  ScheduleDialogComponent,
  ExportComponent,
  PainLevelBarComponent,
  ScheduleListComponent,
  ChartVideoComponent,
  CanvasVideoComponent,
  PainSliderComponent,
  InviteDialogComponent,
  TermsTextComponent,
  PlanContainerComponent,
  MessageDevelopmentComponent,
  TokenListComponent,
  AddOnContainerComponent,
  FooterLinkComponent,
  TimezonePickerComponent,
  FabComponent,
  QuestionnaireComponent,

  DIALOGS,

  PIPES,

  DIRECTIVES,
];

@NgModule({
  declarations: [INTERNAL_DECLARARIONS],
  imports: [
    CommonModule,
    ANGULAR_MODULES,
    MATERIAL_MODULES,
    COVALENT_MODULES,
    EXTERNAL_MODULES,
    TourMatMenuModule,
    Angulartics2Module,
    TranslateModule,
  ],
  exports: [
    ANGULAR_MODULES,
    MATERIAL_MODULES,
    COVALENT_MODULES,
    EXTERNAL_MODULES,
    TourMatMenuModule,
    Angulartics2Module,
    INTERNAL_DECLARARIONS,
    TranslateModule,
  ],
  providers: [SERVICES, WindowRef, NavigatorRef, GUARDS],
})
export class SharedModule {}
