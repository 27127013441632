import { interval, Subscription } from 'rxjs';
import { WindowRef } from '@shared/windowRef';
import { CanvasVideoComponent } from './canvas-video/canvas-video.component';
import { Chart, Patient, Template } from '@models';
import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  HostListener,
  TemplateRef,
} from '@angular/core';
import { debounce } from '@shared/debounce';
import { ResearchService } from '@services';
import * as moment from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';

interface Group {
  Id: string;
  Date: moment.Moment;
}

@Component({
  selector: 'app-chart-video',
  templateUrl: './chart-video.component.html',
  styleUrls: ['./chart-video.component.scss'],
})
export class ChartVideoComponent {
  @ViewChild('dialog') dialog!: TemplateRef<any>;
  @ViewChildren('canvasVideo') canvasVideos!: QueryList<CanvasVideoComponent>;
  groups!: Group[];
  pos = 0;
  templadeOrder = [4, 8, 12, 1, 5, 9, 13, 2, 6, 10, 3, 7, 11];
  w = Math.max(100, Math.min(350 * 4, this.winRef.innerWidth * 0.8) - 48);
  h = Math.max(200, Math.min(700, this.winRef.innerHeight * 0.8));
  dims: { height: number; width: number } = {
    height: this.h,
    width: Math.round(this.w / 4),
  };
  initialized = false;
  isPlaying = false;
  templates!: Template[];
  charts!: Chart[][];
  sub!: Subscription;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.changeGroup(this.pos - 1);
    } else if (event.key === 'ArrowRight') {
      this.changeGroup(this.pos + 1);
    } else if (event.key === 'Space') {
      this.togglePlay();
    }
  }

  @HostListener('window:resize', ['$event'])
  @debounce()
  onResize(event?: Event) {
    this.w = Math.max(
      100,
      Math.min(350 * 4, this.winRef.innerWidth * 0.8) - 48
    );
    this.h = Math.max(200, Math.min(700, this.winRef.innerHeight * 0.8));
    this.dims = { height: this.h, width: Math.round(this.w / 4) };
  }

  constructor(
    public _dialogService: MatDialog,
    private winRef: WindowRef,
    private researchService: ResearchService
  ) {}

  async fetchSubmissions(patient: Patient) {
    return this.researchService
      .getPatient(patient.Id, true, true)
      .toPromise()
      .then((res) => res.Submissions);
  }

  public async open(patient: Patient) {
    this._dialogService.open(this.dialog);

    if (!this.initialized) {
      const submissions = await this.fetchSubmissions(patient);
      const bundle = patient.PatientOrganizations[0].TemplateBundle;

      const templates = bundle.Templates.map((x) => x).sort(
        (a, b) =>
          this.templadeOrder.indexOf(a.Id) - this.templadeOrder.indexOf(b.Id)
      );

      const templateIds = templates.map((x) => x.Id);

      const charts: Chart[][] = templates.map((_) => []);
      const groups: Group[] = [];

      for (const submission of submissions) {
        groups.push(<Group>{ Id: submission.Id, Date: submission.DateCreated });
        for (const chart of submission.Charts) {
          chart.SubmissionId = submission.Id;
          charts[templateIds.indexOf(chart.TemplateId)].push(chart);
        }
      }

      this.templates = templates;
      this.groups = groups;
      this.charts = charts;
      this.initialized = true;
      const sub = this.canvasVideos.changes.subscribe((_) => {
        setTimeout(() => {
          this.canvasVideos.map((c) =>
            c.changeSubmission(this.groups[this.pos].Id)
          );
        }, 500);
        sub.unsubscribe();
      });
    }
  }

  get group(): Group | undefined {
    return this.groups && this.groups.length
      ? this.groups[this.pos]
      : undefined;
  }

  togglePlay() {
    this.isPlaying = !this.isPlaying;

    if (this.isPlaying) {
      this.changeGroup(this.pos + 1);
      this.sub = interval(1000).subscribe(() => {
        if (
          this.groups &&
          this.pos < this.groups.length - 1 &&
          this.isPlaying
        ) {
          this.changeGroup(this.pos + 1);
        } else {
          this.isPlaying = false;
          this.sub.unsubscribe();
        }
      });
    }
  }

  changeGroup(value: number) {
    if (!this.groups) {
      return;
    } else if (
      (this.groups.length && value > this.groups.length - 1) ||
      value < 1 ||
      !value
    ) {
      this.pos = 0;
    } else {
      this.pos = value;
    }

    this.canvasVideos.map((c) => c.changeSubmission(this.groups[this.pos].Id));
  }
}
