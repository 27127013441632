<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="5px"
  style="padding-bottom: 4px"
>
  <mat-card
    fxFlex
    *ngFor="let p of plans"
    [class.selected]="p.name === plan"
    (click)="selectPlan(p)"
  >
    <div fxLayout="column">
      <span class="plan-name" fxLayoutAlign="center" translate
        >PAYMENT.NAMES.{{ p.name }}</span
      >
      <span class="price" fxLayoutAlign="center end">
        {{ p.price | currency: (p.currency | uppercase):'symbol':'1.0-0' }}
        <span class="period"
          >/&nbsp;{{ 'TIMES.year' | translate | lowercase }}</span
        >
      </span>
      <mat-list class="feature-list">
        <div *ngFor="let cat of p.features">
          <p class="cat" *ngIf="cat.category" translate>
            PAYMENT.NAMES.{{ cat.category }}
          </p>
          <div *ngFor="let f of cat.features">
            <div *ngIf="f.blank" fxShow.lt-md style="padding: 24px 0"></div>
            <mat-list-item
              *ngIf="!f.blank"
              [matTooltip]="'PAYMENT.FEATURES.' + f.tooltip | translate"
            >
              &nbsp;-&nbsp;&nbsp;<span
                translate
                [translateParams]="{ quantity: f.quantity | number }"
                >PAYMENT.FEATURES.{{ f.key }}</span
              ></mat-list-item
            >
          </div>
        </div>
      </mat-list>
    </div>
  </mat-card>
</div>
